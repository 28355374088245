import React, { useState, useRef } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import aaaOuvert from "../../assets/anevrisme/aaa_ouvert.jpg";
import angioscannerAaa from "../../assets/anevrisme/angioscanner_aaa.png";
import angioscannerNormal from "../../assets/anevrisme/angioscanner_normal.png";
import aorteNormale from "../../assets/anevrisme/aorte_normale.jpg";
import clampage from "../../assets/anevrisme/clampage.jpeg";
import couverture from "../../assets/anevrisme/couverture.jpg";
import endoprothese1 from "../../assets/anevrisme/endoprothese_1.png";
import endoprothese2 from "../../assets/anevrisme/endoprothese_2.jpg";
import incisions from "../../assets/anevrisme/incisions.png";
import prothese from "../../assets/anevrisme/prothese.jpeg";
import schemaAaa from "../../assets/anevrisme/schema_aaa.png";
import downArrow from "../../assets/icons/down_arrow.png";
import upArrow from "../../assets/icons/up_arrow.png";

const Anevrisme = () => {
  /* useEffect(() => {
    GApageView("landing");
  }, []); */
  const [schemaCestQuoi, setSchemaCestQuoi] = useState(null);
  const [schemaCommentCaSeSoigne, setSchemaCommentCaSeSoigne] = useState(null);
  const [showCestQuoi, setShowCestQuoi] = useState(false);
  const [showEstCeGrave, setShowEstCeGrave] = useState(false);
  const [showCommentCaSeSoigne, setShowCommentCaSeSoigne] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const cestquoiRef = useRef(null);
  const cestquoiSchemaRef = useRef(null);

  const commentcasesoigneSchemaRef = useRef(null);

  function switchSchemas(schema) {
    switch (schema) {
      case "aaaOuvert":
        return (
          <img
            src={aaaOuvert}
            alt="anevrisme de l'aorte ouvert"
            style={{
              display: "flex",
              flex: "1",
              width: "100%",
              height: "100%",
            }}
          />
        );

      case "angioscannerAaa":
        return (
          <img
            src={angioscannerAaa}
            alt="angioscanner d'un anevrisme de l'aorte"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "angioscannerNormal":
        return (
          <img
            src={angioscannerNormal}
            alt="angioscanner normal"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "aorteNormale":
        return (
          <img
            src={aorteNormale}
            alt="aorte normale"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "clampage":
        return (
          <img
            src={clampage}
            alt="clampage"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "couverture":
        return (
          <img
            src={couverture}
            alt="couverture"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "endoprothese1":
        return (
          <img
            src={endoprothese1}
            alt="endoprothèse"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "endoprothese2":
        return (
          <img
            src={endoprothese2}
            alt="endoprothèse"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "incisions":
        return (
          <img
            src={incisions}
            alt="incisions"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "prothese":
        return (
          <img
            src={prothese}
            alt="prothese"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "schemaAaa":
        return (
          <img
            src={schemaAaa}
            alt="schema anevrisme de l'aorte"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      default:
        return <p></p>;
    }
  }

  return (
    <div className="container-section">
      <div
        style={{
          width: "100%",

          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "black",
        }}
      >
        <h1
          ref={cestquoiRef}
          style={{
            fontSize: "500%",
            fontFamily: "Heebo",
            color: "antiquewhite",
            margin: "6%",
          }}
        >
          Anévrisme
        </h1>
        <VerticalTimeline style={{ backgroundColor: "blue" }}>
          {showCestQuoi ? (
            <>
              <VerticalTimelineElement
                id="cestquoi"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "#000" }}
                contentArrowStyle={{ borderRight: "7px solid  #fff" }}
                iconStyle={{
                  background: "#faebd7",
                }}
              >
                <div>
                  <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                    De quoi s'agit-il ?
                  </h3>

                  <div>
                    <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                      Votre médecin vous a peut-être détecté un anévrisme de
                      l’aorte abdominale (AAA). Il s’agit d’une dilatation
                      souvent localisée (comme une hernie) de la plus grosse
                      artère de l’abdomen qui normalement à des bords parallèles
                      et qui mesure entre 15 et 20 mm de diamètre.{" "}
                      <button
                        onClick={() => {
                          setSchemaCestQuoi(switchSchemas("aorteNormale"));
                          cestquoiSchemaRef.current.scrollIntoView();
                        }}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        <u>(schéma aorte normale)</u>
                      </button>
                      <button
                        onClick={() => {
                          setSchemaCestQuoi(
                            switchSchemas("angioscannerNormal")
                          );
                          cestquoiSchemaRef.current.scrollIntoView();
                        }}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        <u>(schéma angioscanner normal)</u>
                      </button>
                      . Avec l’âge, la paroi de l’aorte se fragilise et parfois,
                      tel un ballon, elle peut se dilater{" "}
                      <button
                        onClick={() =>
                          setSchemaCestQuoi(switchSchemas("schemaAaa"))
                        }
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        <u>(schéma d'un anévrisme)</u>
                      </button>
                      . Le diagnostic peut être fait par le médecin traitant
                      mais la palpation de l’anévrisme dans l’abdomen est
                      souvent difficile. Parfois, il est détecté lors d’une
                      échographie réalisée par exemple pour un problème de
                      vésicule biliaire ou de prostate. Il est souvent confirmé
                      par un angioscanner de l’aorte{" "}
                      <button
                        onClick={() =>
                          setSchemaCestQuoi(switchSchemas("angioscannerAaa"))
                        }
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        <u>(schéma angioscanner AAA)</u>
                      </button>
                      . Voici un article qui détaille en profondeur ce
                      diagnostic{" "}
                      <a
                        href="https://www.medisite.fr/anevrisme-anevrisme-de-laorte-abdominale-les-signes.5504939.714177.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ici
                      </a>
                    </p>
                    <div ref={cestquoiSchemaRef}>{schemaCestQuoi}</div>
                    <img
                      src={upArrow}
                      alt=""
                      style={{
                        width: "50px",
                        height: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        /* cestquoiRef.current.scrollIntoView(); */
                        setShowCestQuoi(false);
                      }}
                    />
                  </div>
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                  marginLeft: "12%",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  De quoi s'agit-il ?
                </h3>
                <a href="#cestquoi">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowCestQuoi(true);
                    }}
                  />
                </a>
              </div>
            </div>
          )}

          {showEstCeGrave ? (
            <>
              <VerticalTimelineElement
                id="estcegrave"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <div>
                  <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                    Est-ce grave ?
                  </h3>
                  <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                    Etre porteur d’un anévrysme ne veut pas dire
                    systématiquement intervention chirurgicale, c’est là tout
                    l’intérêt d’une consultation avec un chirurgien vasculaire.
                    Bien que le risque ultime de cet anévrysme soit la{" "}
                    <u>rupture d’anévrisme</u>, il ne faut pas opérer toutes les
                    dilatations aortiques, il faut prendre en charge les
                    anévrysmes les plus gros ({">"} ou = 50 mm) de diamètre.
                    Quand il est décidé, le traitement chirurgical est très
                    efficace et protecteur.
                  </p>
                  <img
                    src={upArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowEstCeGrave(false);
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Est-ce grave ?
                </h3>
                <a href="#estcegrave">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => {
                      /* estcegraveRef.current.scrollIntoView(); */
                      setShowEstCeGrave(true);
                    }}
                  />
                </a>
              </div>
            </div>
          )}
          {showCommentCaSeSoigne ? (
            <>
              <VerticalTimelineElement
                id="commentcasesoigne"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <div>
                  <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                    Comment cela se soigne ?
                  </h3>

                  <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                    Actuellement seule la chirurgie permet de traiter un
                    anévrysme efficacement. Il existe deux traitements possibles
                    :
                    <br />
                    <br />- En chirurgie ouverte :
                    <u>la mise à plat-greffe prothétique</u> C’est une technique
                    qui consiste à remplacer la partie de l’aorte qui est
                    dilatée par une prothèse. Elle nécessite l’ouverture de
                    l’abdomen{" "}
                    <button
                      onClick={() => {
                        setSchemaCommentCaSeSoigne(switchSchemas("incisions"));
                        commentcasesoigneSchemaRef.current.scrollIntoView();
                      }}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <u>(Incisions)</u>
                    </button>
                    . La circulation sanguine est arrêtée temporairement par des
                    clamps{" "}
                    <button
                      onClick={() => {
                        setSchemaCommentCaSeSoigne(switchSchemas("clampage"));
                        commentcasesoigneSchemaRef.current.scrollIntoView();
                      }}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <u>(clampage)</u>
                    </button>
                    , puis l’anévrysme est ouvert{" "}
                    <button
                      onClick={() => {
                        setSchemaCommentCaSeSoigne(switchSchemas("aaaOuvert"));
                        commentcasesoigneSchemaRef.current.scrollIntoView();
                      }}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <u>(AAA ouvert)</u>
                    </button>
                    . Une prothèse est mise en place{" "}
                    <button
                      onClick={() => {
                        setSchemaCommentCaSeSoigne(switchSchemas("prothese"));
                        commentcasesoigneSchemaRef.current.scrollIntoView();
                      }}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <u>(prothèse)</u>
                    </button>{" "}
                    pour remplacer l’anévrysme. Elle est cousue sur l’aorte
                    saine. La prothèse est ensuite recouverte par la paroi de
                    l’ancien anévrysme afin de la protéger de l’intestin{" "}
                    <button
                      onClick={() => {
                        setSchemaCommentCaSeSoigne(switchSchemas("couverture"));
                        commentcasesoigneSchemaRef.current.scrollIntoView();
                      }}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <u>(couverture)</u>
                    </button>
                    . Ainsi l’anévrysme n’existe plus. <br />
                    <br />- En chirurgie endovasculaire :{" "}
                    <u>l’endoprothèse aortique</u>
                    <br />
                    Réservée aux patients fragiles, cette technique consiste à
                    mettre en place une prothèse à l’intérieur de l’anévrysme.
                    Elle est introduite par voie fémorale, sur des guides que
                    l’on positionne dans les artères (d’où le nom
                    “endo”vasculaire), sous contrôle radiologique{" "}
                    <button
                      onClick={() => {
                        setSchemaCommentCaSeSoigne(
                          switchSchemas("endoprothese1")
                        );
                        commentcasesoigneSchemaRef.current.scrollIntoView();
                      }}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <u>(endoprothèse 1)</u>
                    </button>
                    . C’est une technique dite moins invasive, car il n’y a pas
                    d’ouverture de l’abdomen.
                    <br />
                    <br />
                    L’inconvénient est la persistance de l’anévrysme qui est
                    néanmoins exclu de la circulation avec souvent des
                    extensions sur les artères iliaques{" "}
                    <button
                      onClick={() => {
                        setSchemaCommentCaSeSoigne(
                          switchSchemas("endoprothese2")
                        );
                        commentcasesoigneSchemaRef.current.scrollIntoView();
                      }}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <u>(endoprothèse 2)</u>
                    </button>
                    . Une surveillance s’impose régulièrement.
                  </p>
                  <div ref={commentcasesoigneSchemaRef}>
                    {schemaCommentCaSeSoigne}
                  </div>
                  <img
                    src={upArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowCommentCaSeSoigne(false);
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
                marginTop: "10%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Comment cela se soigne ?
                </h3>
                <a href="#commentcasesoigne">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => {
                      setShowCommentCaSeSoigne(true);
                    }}
                  />
                </a>
              </div>
            </div>
          )}
          {showVideo ? (
            <>
              <VerticalTimelineElement
                id="video"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <div>
                  <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                    Vidéo de l'opération
                  </h3>

                  <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                    Vous trouverez{" "}
                    <a
                      href="https://youtu.be/KGrEqrypk4An"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ici
                    </a>{" "}
                    une courte vidéo explicative explicant pas à pas l'opération
                    d'un anévrisme de l'aorte abdominale.
                    <br />
                    <br /> Attention: ce film est un film chirurgical, il est
                    donc fait pour un public <u>averti</u>
                  </p>

                  <img
                    src={upArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowVideo(false);
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Vidéo de l'opération
                </h3>
                <a href="#video">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => {
                      /* estcegraveRef.current.scrollIntoView(); */
                      setShowVideo(true);
                    }}
                  />
                </a>
              </div>
            </div>
          )}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Anevrisme;
