import React, { useState } from "react";
import { Link } from "react-router-dom";
import portrait_pro from "../assets/dr-bourriez.jpg";
import portrait_pro_pp from "../assets/dr-bourriez-pp.png";
import intervention from "../assets/intervention.png";
import photo_publications from "../assets/pile_livres.jpg";
import photo_qualifications from "../assets/diplome.jpg";
import styled, { keyframes } from "styled-components";
import "../style/Presentation.css";
import { useMediaQuery } from "react-responsive";
import downArrow from "../assets/icons/down_arrow.png";

import { fadeIn, fadeInDown, fadeInUp } from "react-animations";

const FadeInDown = styled.div`
  animation: 5s ${keyframes`${fadeInDown}`};
`;

const FadeIn = styled.div`
  animation: 5s ${keyframes`${fadeIn}`};
`;

const FadeInUp = styled.div`
  animation: 5s ${keyframes`${fadeInUp}`};
`;

function Presentation() {
  /* useEffect(() => {
    GApageView("landing");
  }, []); */
  const [showPresentation, setShowPresentation] = useState(false);
  const isDesktopOrTablet = useMediaQuery({
    query: "(min-width: 601px)",
  });
  /* const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1371px)" }); */
  const isMobile = useMediaQuery({
    query: "(max-width: 600px)",
  });
  /* const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" }); */

  return (
    <div style={{ height: "100%" }}>
      {isDesktopOrTablet && (
        <>
          <div className="Presentation">
            <header className="Presentation-header">
              <FadeInDown>
                <div className="titre-presentation">
                  <h1 style={{ fontSize: "380%" }}>Dr Armand Bourriez</h1>
                  <h2 style={{ fontSize: "200%", margin: "3%" }}>
                    Chirurgien vasculaire
                  </h2>
                </div>
              </FadeInDown>
              <FadeInUp>
                <p id="citation">
                  "Un bon chirurgien doit avoir l'oeil d'un aigle, la main d'une
                  femme et le coeur d'un lion."
                  <br />
                  <br />
                  Sir Astley Cooper (1827)
                </p>
              </FadeInUp>
            </header>
            <body className="Presentation-body">
              <div id="texte_et_portrait">
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "stretch",
                    backgroundColor: "#eff3f2",
                  }}
                >
                  <span className="texte-presentation">
                    <p style={{ padding: "1%", color: "black" }}>
                      La vocation chirurgicale du Dr Bourriez lui est venue très
                      jeune. Il a pu concrétiser ce désir d’aider les autres de
                      manière concrète et pragmatique, au prix d’études longues
                      (15 ans) mais passionnantes.
                    </p>
                    <p style={{ padding: "1%", color: "black" }}>
                      Sa formation chirurgicale parisienne (1995-2002) lui a
                      permis d’aborder l’ensemble des facettes de la chirurgie
                      vasculaire dans les plus grands services français de
                      l’Assistance Publique Hôpitaux de Paris (APHP).
                    </p>
                    <p style={{ padding: "1%", color: "black" }}>
                      Installé à Rouen depuis plus de 15 ans, il exerce au sein
                      de 3 établissements:
                    </p>
                  </span>
                  <span className="links">
                    <Link className="link" to="/cliniques/clinique-de-l-europe">
                      Clinique de l'Europe
                    </Link>
                    <Link className="link" to="/cliniques/clinique-megival">
                      Clinique Mégival
                    </Link>
                    <Link
                      className="link"
                      to="/cliniques/clinique-saint-antoine"
                    >
                      Clinique Saint Antoine
                    </Link>
                  </span>
                </div>
                <img
                  src={portrait_pro}
                  className="Presentation-portrait"
                  alt="portrait_docteur_bourriez"
                />
              </div>

              <section>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "stretch",
                    margin: "5%",
                  }}
                >
                  <img
                    src={photo_qualifications}
                    style={{
                      display: "flex",
                      objectFit: "contain",
                      width: "100%",
                      height: "auto",
                      margin: "2%",
                    }}
                    alt="photo_diplome"
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      margin: "2%",
                      height: "auto",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      backgroundColor: "#faebd7",
                      fontWeight: "bold",
                      padding: "3%",
                    }}
                  >
                    <h1
                      style={{
                        fontFamily: "Heebo",
                        fontStyle: "700",
                        fontSize: "220%",
                        margin: "5%",
                      }}
                    >
                      Qualifications
                    </h1>
                    <p
                      style={{
                        fontFamily: "Heebo",
                        fontStyle: "700",
                        fontSize: "100%",
                        margin: "5%",
                        textAlign: "left",
                        lineHeight: "180%",
                      }}
                    >
                      Le Dr Armand Bourriez est un médecin français, diplômé en
                      Chirurgie Vasculaire depuis 2000. Amiénois d’origine, il a
                      réalisé l’ensemble de sa formation chirurgicale à PARIS au
                      sein de l’APHP (Assistance Publique Hôpitaux de Paris).
                    </p>
                    <p
                      style={{
                        fontFamily: "Heebo",
                        fontStyle: "700",
                        fontSize: "100%",
                        marginLeft: "5%",
                        color: "black",
                        textAlign: "left",
                      }}
                    >
                      <Link
                        style={{
                          color: "#000",
                        }}
                        to="/qualifications"
                      >
                        <u>Aller voir les qualifications {">"}</u>
                      </Link>
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "stretch",
                    margin: "5%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      width: "100%",
                      margin: "2%",
                      height: "auto",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      backgroundColor: "#4b7973",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    <h1
                      style={{
                        fontFamily: "Heebo",
                        fontStyle: "700",
                        fontSize: "220%",
                        margin: "5%",
                      }}
                    >
                      Interventions
                    </h1>
                    <p
                      style={{
                        fontFamily: "Heebo",
                        fontStyle: "700",
                        fontSize: "100%",
                        margin: "5%",
                        textAlign: "left",
                        lineHeight: "180%",
                      }}
                    >
                      En opérant plus de 600 patients par an, dans les 3
                      domaines qu’il affectionne (chirurgie artérielle,
                      chirurgie veineuse et chirurgie des fistules
                      arterio-veineuse) le Dr Bourriez a développé une
                      expertise, la mettant au service de ses patients.
                    </p>
                    <p
                      style={{
                        fontFamily: "Heebo",
                        fontStyle: "700",
                        fontSize: "100%",
                        margin: "5%",
                        color: "#FFF",
                        textAlign: "left",
                      }}
                    >
                      <Link
                        style={{
                          color: "#FFF",
                        }}
                        to="/interventions"
                      >
                        <u>Aller voir les interventions {">"}</u>
                      </Link>
                    </p>
                  </div>
                  <img
                    src={intervention}
                    style={{
                      display: "flex",
                      objectFit: "contain",
                      width: "100%",
                      height: "auto",
                      margin: "2%",
                    }}
                    alt="photo_intervention"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "stretch",
                    margin: "5%",
                  }}
                >
                  <img
                    src={photo_publications}
                    style={{
                      display: "flex",
                      objectFit: "contain",
                      width: "100%",
                      height: "auto",
                      margin: "2%",
                    }}
                    alt="photo_livre"
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      margin: "2%",
                      height: "auto",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      backgroundColor: "#faebd7",
                      fontWeight: "bold",
                    }}
                  >
                    <h1
                      style={{
                        fontFamily: "Heebo",
                        fontStyle: "700",
                        fontSize: "220%",
                        margin: "5%",
                      }}
                    >
                      Publications
                    </h1>
                    <p
                      style={{
                        fontFamily: "Heebo",
                        fontStyle: "700",
                        fontSize: "100%",
                        margin: "5%",
                        textAlign: "left",
                        lineHeight: "180%",
                      }}
                    >
                      Le Dr Armand Bourriez est l'auteur de nombreuses
                      conférences et publications scientifiques dans des congrès
                      et journaux spécialisés, français et étrangers (cf Titres
                      et Travaux). Il participe chaque année depuis plus de 15
                      ans à de nombreuses conférences et symposium.
                    </p>
                    <p
                      style={{
                        fontFamily: "Heebo",
                        fontStyle: "700",
                        fontSize: "100%",
                        margin: "5%",
                        color: "#FFF",
                        textAlign: "left",
                      }}
                    >
                      <Link
                        style={{
                          color: "#000",
                        }}
                        to="/qualifications#publications"
                      >
                        <u>Aller voir les publications {">"}</u>
                      </Link>
                    </p>
                  </div>
                </div>
              </section>
            </body>
            <p
              style={{
                fontFamily: "Heebo",
                fontStyle: "700",
                fontSize: "100%",
                margin: "5%",
                color: "#FFF",
                textAlign: "left",
              }}
            >
              <Link
                style={{
                  color: "#000",
                }}
                to="/mentionslegales"
              >
                <u>Docteur Armand Bourriez | Mentions légales 2021</u>
              </Link>
            </p>
          </div>
        </>
      )}
      {isMobile && (
        <>
          <div className="Presentation">
            <header
              className="Presentation-header"
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={portrait_pro_pp}
                style={{
                  display: "flex",
                  width: "40%",
                  margin: "8%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                alt="portrait_docteur_bourriez"
              />
              <div className="titre-presentation">
                <h1 style={{ fontSize: "250%" }}>Dr Armand Bourriez</h1>
                <h2 style={{ fontSize: "150%", margin: "3%" }}>
                  Chirurgien vasculaire
                </h2>
              </div>

              <p
                style={{
                  fontStyle: "italic",
                  fontWeight: "800",
                  fontSize: "90%",
                  margin: "4%",
                  fontFamily: "Heebo",
                }}
              >
                "Un bon chirurgien doit avoir l'oeil d'un aigle, la main d'une
                femme et le coeur d'un lion."
                <br />
                <br />
                Sir Astley Cooper (1827)
              </p>
            </header>
            {showPresentation ? (
              <FadeIn>
                <body className="Presentation-body">
                  <div id="texte">
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        height: "100%",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "stretch",
                        backgroundColor: "#eff3f2",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          flex: 1,
                          margin: "3%",
                          lineHeight: "2.5rem",
                          fontSize: "120%",
                          fontFamily: "Heebo",
                          justifyContent: "center",
                          textAlign: "center",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ padding: "1%", color: "black" }}>
                          La vocation chirurgicale du Dr Bourriez lui est venue
                          très jeune. Il a pu concrétiser ce désir d’aider les
                          autres de manière concrète et pragmatique, au prix
                          d’études longues (15 ans) mais passionnantes.
                        </p>
                        <p style={{ padding: "1%", color: "black" }}>
                          Sa formation chirurgicale parisienne (1995-2002) lui a
                          permis d’aborder l’ensemble des facettes de la
                          chirurgie vasculaire dans les plus grands services
                          français de l’Assistance Publique Hôpitaux de Paris
                          (APHP).
                        </p>
                        <p style={{ padding: "1%", color: "black" }}>
                          Installé à Rouen depuis plus de 15 ans, il exerce au
                          sein de 3 établissements:
                        </p>
                      </span>
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          padding: "2%",
                          fontFamily: "Heebo",
                        }}
                      >
                        <Link
                          className="link"
                          to="/cliniques/clinique-de-l-europe"
                        >
                          Clinique de l'Europe
                        </Link>
                        <Link className="link" to="/cliniques/clinique-megival">
                          Clinique Mégival
                        </Link>
                        <Link
                          className="link"
                          to="/cliniques/clinique-saint-antoine"
                        >
                          Clinique Saint Antoine
                        </Link>
                      </span>
                    </div>
                  </div>
                </body>
              </FadeIn>
            ) : (
              <div
                style={{
                  backgroundColor: "black",
                  color: "antiquewhite",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "2%",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                  margin: "2%",
                  borderRadius: 30,
                }}
              >
                <p>Présentation</p>
                <a href="#texte">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => {
                      setShowPresentation(true);
                    }}
                  />
                </a>
              </div>
            )}
          </div>
          <p
            style={{
              fontFamily: "Heebo",
              fontStyle: "700",
              fontSize: "100%",
              margin: "5%",
              color: "#FFF",
              textAlign: "left",
            }}
          >
            <Link
              style={{
                color: "#000",
              }}
              to="/mentionslegales"
            >
              <u>Docteur Armand Bourriez | Mentions légales 2021</u>
            </Link>
          </p>
        </>
      )}
    </div>
  );
}

export default Presentation;
