import React, { useState, useRef } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import schemaFAV from "../../assets/fistule/schema_fav.png";
import schemaPonction from "../../assets/fistule/ponction_fav.png";
import downArrow from "../../assets/icons/down_arrow.png";
import upArrow from "../../assets/icons/up_arrow.png";

const Fistule = () => {
  const [schemaEstCeGrave, setSchemaEstCeGrave] = useState(null);
  const [schemaCommentCaSeSoigne, setSchemaCommentCaSeSoigne] = useState(null);
  const [showCestQuoi, setShowCestQuoi] = useState(false);
  const [showEstCeGrave, setShowEstCeGrave] = useState(false);
  const [showCommentCaSeSoigne, setShowCommentCaSeSoigne] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showPrecautions, setShowPrecautions] = useState(false);

  const cestquoiRef = useRef(null);

  const schemaRef = useRef(null);

  function switchSchemas(schema) {
    switch (schema) {
      case "schemaFAV":
        return (
          <img
            src={schemaFAV}
            alt="schema de fistule artério-veineuse"
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          />
        );

      case "schemaPonction":
        return (
          <img
            src={schemaPonction}
            alt="schema d'une ponction de fistule artério-veineuse"
            style={{
              width: "105%",
              height: "100%",
            }}
          />
        );

      default:
        return <p></p>;
    }
  }

  return (
    <div className="container-section">
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "black",
        }}
      >
        <h1
          ref={cestquoiRef}
          style={{
            fontSize: "500%",
            fontFamily: "Heebo",
            color: "antiquewhite",
            margin: "6%",
          }}
        >
          Fistule
        </h1>
        <VerticalTimeline>
          {showCestQuoi ? (
            <>
              <VerticalTimelineElement
                id="cestquoifistule"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "#000" }}
                contentArrowStyle={{ borderRight: "7px solid  #fff" }}
                iconStyle={{
                  background: "#faebd7",
                }}
              >
                <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                  De quoi s'agit-il ?
                </h3>

                <div>
                  <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                    Pour être dialysé, il faut un abord vasculaire, c’est à dire
                    un système qui permette de prélever le sang à débit optimal
                    pour le filtrer, faire les échanges de dialyse et le
                    réinjecter “purifié” dans la circulation sanguine.
                    Schématiquement il y a deux types d’abords vasculaires:
                    <br /> 1. La fistule artério-veineuse (FAV)
                    <br />
                    2. le cathéter tunnélisé
                    <br />
                    Nous allons vous décrire ici la réalisation de la FAV.
                  </p>

                  <img
                    src={upArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowCestQuoi(false);
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                  marginLeft: "12%",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  De quoi s'agit-il ?
                </h3>
                <a href="#cestquoifistule">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => setShowCestQuoi(true)}
                  />
                </a>
              </div>
            </div>
          )}
          {showEstCeGrave ? (
            <>
              <VerticalTimelineElement
                id="estcegravefistule"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                  La création de fistule artérielle en pratique
                </h3>

                <p
                  style={{
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Il s’agit d’une connexion créée par le chirurgien entre une
                  artère et une veine superficielle de l’avant-bras ou du bras.
                  L'afflux de sang de l'artère vers la veine sous cutanée permet
                  d'augmenter de façon importante le débit dans cette dernière
                  qui va se dilater progressivement et qui deviendra
                  artérialisée{" "}
                  <button
                    onClick={() => {
                      setSchemaEstCeGrave(switchSchemas("schemaFAV"));
                    }}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    <u>(schéma FAV)</u>
                  </button>
                  . Elle est créée au cours d’une petite opération chirurgicale
                  sous anesthésie locorégionale, en ambulatoire (une journée).
                  Après la réalisation de 2 examens complémentaires prescrits
                  par votre néphrologue (Phlébographie (étude des veines) et
                  écho-doppler (étude des artères)), le chirurgien choisi une
                  artère du de l’avant-bras ou du bras à proximité d'une veine
                  superficielle et réalise une anastomose (suture) entre les
                  deux vaisseaux. Le côté choisi est habituellement le côté non
                  dominant (gauche pour un droitier). L’intervention dure en
                  moyenne une heure.
                </p>
                <div>{schemaEstCeGrave}</div>
                <img
                  src={upArrow}
                  alt=""
                  style={{
                    width: "50px",
                    height: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowEstCeGrave(false);
                  }}
                />
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  En pratique
                </h3>
                <a href="#estcegravefistule">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => setShowEstCeGrave(true)}
                  />
                </a>
              </div>
            </div>
          )}
          {showCommentCaSeSoigne ? (
            <>
              <VerticalTimelineElement
                id="commentcasesoignevarices"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                  Et après ?
                </h3>

                <p
                  style={{
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Après un temps de maturation, et parfois quelques retouches
                  chirurgicales, elle garantit un débit sanguin optimal pour une
                  filtration rapide de votre sang et permet ainsi les échanges
                  de dialyse. Lors de la dialyse, deux aiguilles sont utilisées
                  pour la ponction dans la fistule (1) : • l’aiguille d’amont
                  (dite artérielle) servant à l’aspiration du sang à épurer est
                  placée au point le plus proche de l’anastomose. • l’aiguille
                  d’aval (dite veineuse) servant à la réinjection du sang épuré
                  par la machine de dialyse est placée plus haut sur le trajet
                  de la veine
                  <button
                    onClick={() => {
                      setSchemaCommentCaSeSoigne(
                        switchSchemas("schemaPonction")
                      );
                      schemaRef.current.scrollIntoView();
                    }}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    <u>(ponction de FAV)</u>
                  </button>
                </p>
                <div ref={schemaRef}>{schemaCommentCaSeSoigne}</div>
                <img
                  src={upArrow}
                  alt=""
                  style={{
                    width: "50px",
                    height: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    /* cestquoiRef.current.scrollIntoView(); */
                    setShowCommentCaSeSoigne(false);
                  }}
                />
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
                marginTop: "1%",
                marginLeft: "18%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Et après ?
                </h3>
                <a href="#commentcasesoignevarices">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => setShowCommentCaSeSoigne(true)}
                  />
                </a>
              </div>
            </div>
          )}
          {showPrecautions ? (
            <>
              <VerticalTimelineElement
                id="precautions"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <div>
                  <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                    Précautions
                  </h3>

                  <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                    Il est conseillé de protéger la fistule des traumatismes et
                    plaies éventuelles, d'éviter les efforts importants au
                    niveau du bras concerné, d'éviter de dormir sur ce bras, de
                    porter montre ou bijoux sur le membre concerné, de
                    l'utiliser pour prendre la tension ou pour un prélèvement
                    sanguin.
                  </p>

                  <img
                    src={upArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowPrecautions(false);
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Précautions
                </h3>
                <a href="#precautions">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => {
                      /* estcegraveRef.current.scrollIntoView(); */
                      setShowPrecautions(true);
                    }}
                  />
                </a>
              </div>
            </div>
          )}
          {showVideo ? (
            <>
              <VerticalTimelineElement
                id="video"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <div>
                  <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                    Vidéo de l'opération
                  </h3>

                  <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                    Vous trouverez{" "}
                    <a
                      href="https://youtu.be/KGrEqrypk4An"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ici
                    </a>{" "}
                    une courte vidéo explicative explicant pas à pas l'opération
                    d'une fistule.
                    <br />
                    <br /> Attention: ce film est un film chirurgical, il est
                    donc fait pour un public <u>averti</u>
                  </p>

                  <img
                    src={upArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowVideo(false);
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "flex-start",
                marginLeft: "9%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Vidéo de l'opération
                </h3>
                <a href="#video">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => {
                      /* estcegraveRef.current.scrollIntoView(); */
                      setShowVideo(true);
                    }}
                  />
                </a>
              </div>
            </div>
          )}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Fistule;
