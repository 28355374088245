import React, { useState, useRef } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import schemaImage1 from "../../assets/arterite/arterite1.jpg";
import schemaImage2 from "../../assets/arterite/arterite2.jpg";
import schemaImage3 from "../../assets/arterite/arterite3.jpg";
import downArrow from "../../assets/icons/down_arrow.png";
import upArrow from "../../assets/icons/up_arrow.png";

const Artérite = () => {
  /* useEffect(() => {
    GApageView("landing");
  }, []); */
  const [schemaCommentCaSeSoigne, setSchemaCommentCaSeSoigne] = useState(null);
  const [showCestQuoi, setShowCestQuoi] = useState(false);
  const [showEstCeGrave, setShowEstCeGrave] = useState(false);
  const [showCommentCaSeSoigne, setShowCommentCaSeSoigne] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const cestquoiRef = useRef(null);

  const commentcasesoigneRef = useRef(null);
  const schemaRef = useRef(null);

  function switchSchemas(schema) {
    switch (schema) {
      case "schema1":
        return (
          <img
            src={schemaImage1}
            alt="schema1"
            style={{
              display: "flex",

              width: "100%",
              height: "120%",
            }}
          />
        );

      case "schema2":
        return (
          <img
            src={schemaImage2}
            alt="schema2"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "schema3":
        return (
          <img
            src={schemaImage3}
            alt="schema3"
            style={{
              width: "50%",
              height: "50%",
            }}
          />
        );
      default:
        return <p></p>;
    }
  }

  return (
    <div className="container-section">
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "black",
        }}
      >
        <h1
          ref={cestquoiRef}
          style={{
            fontSize: "500%",
            fontFamily: "Heebo",
            color: "antiquewhite",
            margin: "6%",
          }}
        >
          Artérite
        </h1>
        <VerticalTimeline>
          {showCestQuoi ? (
            <>
              <VerticalTimelineElement
                id="cestquoiarterite"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "#000" }}
                contentArrowStyle={{ borderRight: "7px solid  #fff" }}
                iconStyle={{
                  background: "#faebd7",
                }}
              >
                <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                  De quoi s'agit-il ?
                </h3>

                <div>
                  <p
                    style={{
                      fontFamily: "Heebo, sans-serif",
                      fontWeight: "700",
                    }}
                  >
                    Votre médecin vous a peut-être détecté un problème au niveau
                    des artères de la jambe. Dans la majorité des cas, ce
                    problème est dû à une artérite oblitérante des membres
                    inférieurs (A.O.M.I) qui est une maladie qui touche
                    préférentiellement les fumeurs et les diabétiques. Elle se
                    manifeste par des rétrécissements (sténoses) ou des
                    obstructions (thromboses) des artères qui irriguent les
                    jambes. <br />
                    <br />
                    Symptômes : Les patients ont fréquemment des douleurs à la
                    marche, dans le mollet et/ou la cuisse et/ou la fesse (en
                    fonction du siège du rétrécissement). Cette douleur survient
                    après une certaine distance (périmètre de marche) puis
                    disparaît à l’arrêt de l’effort, on appelle cela une
                    claudication intermittente.
                  </p>
                  <img
                    src={upArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowCestQuoi(false);
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                  marginLeft: "12%",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  De quoi s'agit-il ?
                </h3>
                <a href="#cestquoiarterite">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => setShowCestQuoi(true)}
                  />
                </a>
              </div>
            </div>
          )}
          {showEstCeGrave ? (
            <>
              <VerticalTimelineElement
                id="estcegravearterite"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                  Est-ce grave ?
                </h3>

                <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                  Etre porteur d’une artérite ne veut pas dire systématiquement
                  intervention chirurgicale, c’est là tout l’interêt d’une
                  consultation avec un chirurgien vasculaire. Cependant
                  l’artérite n’est pas à négliger car elle est souvent
                  évolutive. A un stade plus avancé, les douleurs peuvent
                  survenir la nuit provoquant des insomnies. A un stade ultime,
                  le pied n’est plus irrigué, cela se manifeste par de la
                  gangrène. <br />
                  <br />
                  C’est au décours de la consultation après analyse d’examens
                  complémentaires que votre chirurgien décidera d’une indication
                  opératoire. Votre chirurgien vasculaire vous expliquera tous
                  les détails de l’intervention, les risques et bénéfices de
                  celle-ci au cours de la consultation si une indication doit
                  être posée.
                </p>
                <p ref={commentcasesoigneRef}></p>
                <img
                  src={upArrow}
                  alt=""
                  style={{
                    width: "50px",
                    height: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    /* cestquoiRef.current.scrollIntoView(); */
                    setShowEstCeGrave(false);
                  }}
                />
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Est-ce grave ?
                </h3>
                <a href="#estcegravearterite">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => setShowEstCeGrave(true)}
                  />
                </a>
              </div>
            </div>
          )}
          {showCommentCaSeSoigne ? (
            <>
              <VerticalTimelineElement
                id="commentcasesoignearterite"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                  Comment cela se soigne ?
                </h3>

                <p
                  style={{
                    fontFamily: "Heebo",
                    fontWeight: "700",
                    textAlign: "justify",
                  }}
                >
                  - <u>Le traitement médical :</u> Le premier traitement est
                  médical, il consiste en un arrêt complet du tabac et à
                  favoriser une marche très régulière (1 à 2 kilomètres par
                  jour) de manière à développer des petites artères qui vont
                  irriguer les jambes et les pieds. On y associe un médicament
                  qui fluidifie le sang (antiagrégant plaquettaire : ASPIRINE ou
                  KARDÉGIC ou ASPÉGIC ou PLAVIX) et/ou des médicaments qui
                  limitent l’évolution de la plaque d’athérome :
                  hypolipidémiants qui diminuent le taux de cholestérol et de
                  triglycérides dans le sang, anti-diabétiques qui diminuent le
                  taux de sucre dans le sang et anti-hypertenseurs qui
                  normalisent la tension artérielle.
                  <br />
                  <br />- <u>Le traitement chirurgical :</u> si le traitement
                  médical est insuffisant, un traitement chirurgical
                  complémentaire peut être associé afin d’éviter les
                  complications ultérieures. De nombreuses possibilités
                  techniques sont envisagées en fonction des artères touchées.
                  Schématiquement, il existe deux types de traitement:
                  <br />
                  <br />- soit un traitement dit <g>endovasculaire</g> :{" "}
                  <button
                    onClick={() => {
                      setSchemaCommentCaSeSoigne(switchSchemas("schema1"));
                      schemaRef.current.scrollIntoView();
                    }}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    <u>(schéma 1)</u>
                  </button>
                  il consiste à réaliser une dilatation à l’aide d’un ballonnet
                  positionné à l’intérieur de l’artère. Communément, le
                  chirurgien pique l’artère fémorale, au niveau de l’aine, sous
                  anesthésie. A travers l’aiguille, il met en place un guide qui
                  franchi le rétrécissement. Sur ce guide, et sous contrôle
                  radiologique le ballonnet (de la taille de l’artère) est
                  gonflé à l‘endroit de la sténose. Si le résultat est
                  satisfaisant , le chirurgien retire tout le matériel, sinon il
                  met en place une prothèse métallique appelée "stent".
                  <br />
                  <br />- soit un traitement dit par chirurgie ouverte.
                  <br />
                  {"   "} 1. L’endartériectomie :{" "}
                  <button
                    onClick={() => {
                      setSchemaCommentCaSeSoigne(switchSchemas("schema2"));
                      schemaRef.current.scrollIntoView();
                    }}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    <u>(schéma 2)</u>
                  </button>
                  Le chirurgien ouvre l’artère à l’endroit de la sténose,
                  habituellement dans le sens de la longueur. Puis, à l’aide
                  d’une pince, il retire la plaque d’athérome pour que le sang
                  circule librement. Pour refermer l’artère, il peut utiliser un
                  morceau de veine ou de prothèse appelé patch, qui est suturé
                  sur l’artère avec du fil très fin.
                  <br />
                  {"   "} 2. Le pontage :{" "}
                  <button
                    onClick={() => {
                      setSchemaCommentCaSeSoigne(switchSchemas("schema3"));
                      schemaRef.current.scrollIntoView();
                    }}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    <u>(schéma 3)</u>
                  </button>
                  Le principe est de passer en » pont » au-dessus de l’artère
                  obstruée. Le chirurgien effectue des ouvertures cutanées en
                  regard des zones à suturées. La circulation sanguine est
                  interrompue temporairement, l’artère est ouverte et la
                  prothèse est cousue avec du fil très fin selon le même
                  principe que pourl’ endartériectomie. Ainsi, le sang est
                  détourné pour passer dans le pontage, avec d’irriguer les
                  artères de la jambe.{" "}
                </p>

                <div ref={schemaRef}>{schemaCommentCaSeSoigne}</div>
                <img
                  src={upArrow}
                  alt=""
                  style={{
                    width: "50px",
                    height: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowCommentCaSeSoigne(false);
                  }}
                />
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
                marginTop: "10%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Comment cela se soigne ?
                </h3>
                <a href="#commentcasesoignearterite">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => setShowCommentCaSeSoigne(true)}
                  />
                </a>
              </div>
            </div>
          )}
          {showVideo ? (
            <>
              <VerticalTimelineElement
                id="video"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <div>
                  <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                    Vidéo de l'opération
                  </h3>

                  <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                    Vous trouverez{" "}
                    <a
                      href="https://youtu.be/uH2HR1Q8hos"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ici
                    </a>{" "}
                    une courte vidéo explicative explicant pas à pas l'opération
                    d'une artérite.
                    <br />
                    <br /> Attention: ce film est un film chirurgical, il est
                    donc fait pour un public <u>averti</u>
                  </p>

                  <img
                    src={upArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowVideo(false);
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Vidéo de l'opération
                </h3>
                <a href="#video">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => {
                      /* estcegraveRef.current.scrollIntoView(); */
                      setShowVideo(true);
                    }}
                  />
                </a>
              </div>
            </div>
          )}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Artérite;
