import React from "react";
import "../../style/Cliniques.css";
import Place from "../../components/Place";
import styled, { keyframes } from "styled-components";
import { useMediaQuery } from "react-responsive";

import photo_europe from "../../assets/clinique_europe.jpg";
import { fadeIn } from "react-animations";

const FadeIn = styled.div`
  animation: 4s ${keyframes`${fadeIn}`};
`;

const Europe = () => {
  /* useEffect(() => {
    GApageView("landing");
  }, []); */
  const isDesktopOrTablet = useMediaQuery({
    query: "(min-width: 601px)",
  });
  /* const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1371px)" }); */
  const isMobile = useMediaQuery({
    query: "(max-width: 600px)",
  });
  /* const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" }); */

  return (
    <div className="container-section">
      {isDesktopOrTablet && (
        <>
          <section
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <img
              src={photo_europe}
              className="parallax"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: "-1",
                filter: `blur(2px)`,
              }}
              alt="clinique_de_l_europe"
            />
            <h1 className="titre-section">Clinique de l'Europe</h1>
          </section>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "90%",
            }}
          >
            <h1
              style={{
                display: "flex",
                fontSize: "300%",
                fontFamily: "Heebo",
                margin: "2.5%",
              }}
            >
              À propos
            </h1>
            <FadeIn className="description-clinique">
              <h4>
                L'ensemble des gestes de chirurgie vasculaire, de la chirurgie
                aortique lourde à la chirurgie veineuse en ambulatoire, ainsi
                que la prise en charge des abords vasculaires d’hémodialyse,
                peuvent s'effectuer à la Clinique de l'Europe.
                <br />
                <br />
                Elle a fait récemment l'acquisition d'une salle Hybride haut de
                gamme, permettant de réaliser des interventions chirurgicales
                classiques et endovasculaires.
              </h4>
            </FadeIn>
          </div>
          <section>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "stretch",
                margin: "5%",
              }}
            >
              <iframe
                src="https://www.youtube.com/embed/2iLymEXN3Jg"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="true"
                style={{
                  display: "flex",
                  width: "120%",
                  height: "auto",
                  margin: "2%",
                }}
                title="video_clinique_europe"
              ></iframe>
              <div
                style={{
                  display: "flex",
                  width: "120%",
                  margin: "2%",
                  height: "auto",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  backgroundColor: "#faebd7",
                  fontWeight: "bold",
                  padding: "3%",
                }}
              >
                <h1
                  style={{
                    fontFamily: "Heebo",
                    fontStyle: "700",
                    fontSize: "220%",
                    margin: "5%",
                  }}
                >
                  Salle hybride
                </h1>
                <p
                  style={{
                    fontFamily: "Heebo",
                    fontStyle: "700",
                    fontSize: "100%",
                    margin: "5%",
                    textAlign: "left",
                    lineHeight: "180%",
                  }}
                >
                  Présentation de la salle Hybride, installée récemment au sein
                  de la Clinique de l'Europe.
                </p>
              </div>
            </div>
          </section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "70%",
              padding: "4%",
            }}
          >
            <Place
              title="Clinique de l'Europe"
              adress="73 Boulevard de l'Europe, 76100 Rouen"
              phone="02 32 18 10 00"
              src={photo_europe}
              url={"https://chpeurope-rouen.vivalto-sante.com/"}
              mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2595.1035673112856!2d1.0839435157638073!3d49.42585597934712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e0de69514e6197%3A0xaf6080ec69eee9af!2sClinique%20de%20l&#39;Europe!5e0!3m2!1sfr!2sfr!4v1599140551018!5m2!1sfr!2sfr"
            />
          </div>
        </>
      )}
      {isMobile && (
        <>
          <section
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <img
              src={photo_europe}
              className="parallax"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: "-1",
                filter: `blur(2px)`,
              }}
              alt="clinique_de_l_europe"
            />
            <h1 className="titre-section">Clinique de l'Europe</h1>
          </section>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "90%",
            }}
          >
            <h1
              style={{
                display: "flex",
                fontSize: "300%",
                fontFamily: "Heebo",
                margin: "2.5%",
              }}
            >
              À propos
            </h1>
            <FadeIn className="description-clinique">
              <h4>
                L'ensemble des gestes de chirurgie vasculaire, de la chirurgie
                aortique lourde à la chirurgie veineuse en ambulatoire, ainsi
                que la prise en charge des abords vasculaires d’hémodialyse,
                peuvent s'effectuer à la Clinique de l'Europe.
                <br />
                <br />
                Elle a fait récemment l'acquisition d'une salle Hybride haut de
                gamme, permettant de réaliser des interventions chirurgicales
                classiques et endovasculaires.
              </h4>
            </FadeIn>
          </div>
          <section>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "10%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  margin: "6%",
                  height: "auto",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  backgroundColor: "#faebd7",
                  fontWeight: "bold",
                  padding: "3%",
                }}
              >
                <h1
                  style={{
                    fontFamily: "Heebo",
                    fontStyle: "700",
                    fontSize: "220%",
                    margin: "5%",
                  }}
                >
                  Salle hybride
                </h1>
                <p
                  style={{
                    fontFamily: "Heebo",
                    fontStyle: "700",
                    fontSize: "100%",
                    margin: "5%",
                    textAlign: "left",
                    lineHeight: "180%",
                  }}
                >
                  Présentation de la salle Hybride, installée récemment au sein
                  de la Clinique de l'Europe.
                </p>
              </div>
              <iframe
                src="https://www.youtube.com/embed/2iLymEXN3Jg"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="true"
                style={{
                  display: "flex",
                  width: "100%",
                  height: "200%",
                  margin: "2%",
                }}
                title="video_clinique_europe"
              ></iframe>
            </div>
          </section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "150%",
              padding: "4%",
            }}
          >
            <Place
              title="Clinique de l'Europe"
              adress="73 Boulevard de l'Europe, 76100 Rouen"
              phone="02 32 18 10 00"
              src={photo_europe}
              url={"https://chpeurope-rouen.vivalto-sante.com/"}
              mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2595.1035673112856!2d1.0839435157638073!3d49.42585597934712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e0de69514e6197%3A0xaf6080ec69eee9af!2sClinique%20de%20l&#39;Europe!5e0!3m2!1sfr!2sfr!4v1599140551018!5m2!1sfr!2sfr"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Europe;
