import React, { useState, useRef } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import schemaImage1 from "../../assets/varices/varicesStripping.png";
import schemaImage2 from "../../assets/varices/varicesTEV1.png";
import schemaImage3 from "../../assets/varices/varicesTEV2.png";
import schemaImage4 from "../../assets/varices/varicesTEV3.png";
import schemaImage5 from "../../assets/varices/varices1.png";
import downArrow from "../../assets/icons/down_arrow.png";
import upArrow from "../../assets/icons/up_arrow.png";

const Varices = () => {
  /* useEffect(() => {
    GApageView("landing");
  }, []); */
  const [schemaCestQuoi, setSchemaCestQuoi] = useState(null);
  const [schemaCommentCaSeSoigne, setSchemaCommentCaSeSoigne] = useState(null);
  const [showCestQuoi, setShowCestQuoi] = useState(false);
  const [showEstCeGrave, setShowEstCeGrave] = useState(false);
  const [showCommentCaSeSoigne, setShowCommentCaSeSoigne] = useState(false);

  const cestquoiRef = useRef(null);

  const schemaRef = useRef(null);

  function switchSchemas(schema) {
    switch (schema) {
      case "schema1":
        return (
          <img
            src={schemaImage1}
            alt="schema1"
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          />
        );

      case "schema2":
        return (
          <img
            src={schemaImage2}
            alt="schema2"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "schema3":
        return (
          <img
            src={schemaImage3}
            alt="schema3"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "schema4":
        return (
          <img
            src={schemaImage4}
            alt="schema4"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "schema5":
        return (
          <img
            src={schemaImage5}
            alt="schema5"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      default:
        return <p></p>;
    }
  }

  return (
    <div className="container-section">
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "black",
        }}
      >
        <h1
          ref={cestquoiRef}
          style={{
            fontSize: "500%",
            fontFamily: "Heebo",
            color: "antiquewhite",
            margin: "6%",
          }}
        >
          Varices
        </h1>
        <VerticalTimeline>
          {showCestQuoi ? (
            <>
              <VerticalTimelineElement
                id="cestquoivarices"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "#000" }}
                contentArrowStyle={{ borderRight: "7px solid  #fff" }}
                iconStyle={{
                  background: "#faebd7",
                }}
              >
                <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                  De quoi s'agit-il ?
                </h3>

                <div>
                  <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                    Il s’agit d’une dégradation du réseau veineux superficiel
                    (veines saphènes). Dans l’organisme, il existe le système
                    veineux profond (très important il ramène 90% du sang vers
                    le cœur) et le système veineux superficiel. Lorsque l’on
                    parle communément de « varices » c’est le système veineux
                    superficiel qui est touché par une incontinence, c’est à
                    dire qu’en position debout le sang ne remonte pas vers le
                    cœur mais descend dans la veine qui se dilate avec le temps.
                    <button
                      onClick={() =>
                        setSchemaCestQuoi(switchSchemas("schema5"))
                      }
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <u>(schéma 1)</u>
                    </button>{" "}
                    La position debout prolongée favorise le développement de
                    ces varices. C’est l’examen écho-doppler réalisé par un
                    médecin vasculaire / phlébologue qui confirmera le
                    diagnostic.
                  </p>
                  <div>{schemaCestQuoi}</div>
                  <img
                    src={upArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowCestQuoi(false);
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                  marginLeft: "12%",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  De quoi s'agit-il ?
                </h3>
                <a href="#cestquoivarices">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => setShowCestQuoi(true)}
                  />
                </a>
              </div>
            </div>
          )}
          {showEstCeGrave ? (
            <>
              <VerticalTimelineElement
                id="estcegravevarices"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                  Est-ce grave ?
                </h3>

                <p
                  style={{
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Non, cela n’est pas grave. Les conséquences sont
                  essentiellement : <br />- esthétiques (grosses veines visibles
                  sur la jambe) <br />- fonctionnelles (jambes lourdes surtout
                  en fin de journée) <br />- dermatologiques (ulcères veineux,
                  dermite ocre, hypodermite) à long terme.
                  <br />
                  <br /> La thrombose veineuse superficielle ou paraphlébite est
                  la formation d’un caillot sur une veine dilatée, c’est
                  douloureux mais non dangereux. C’est pour éviter ces
                  différentes évolutions que l’on conseille de traiter les
                  varices
                </p>
                <img
                  src={upArrow}
                  alt=""
                  style={{
                    width: "50px",
                    height: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowEstCeGrave(false);
                  }}
                />
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Est-ce grave ?
                </h3>
                <a href="#estcegravevarices">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => setShowEstCeGrave(true)}
                  />
                </a>
              </div>
            </div>
          )}
          {showCommentCaSeSoigne ? (
            <>
              <VerticalTimelineElement
                id="commentcasesoignevarices"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                  Comment cela se soigne ?
                </h3>

                <p
                  style={{
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Le premier traitement est médical, il est indispensable, il
                  est basé sur : <br />
                  1. Exercice physique, la marche augmente la vidange veineuse.{" "}
                  <br />
                  2. Eviter l'orthostatisme prolongé.
                  <br /> 3. Contention élastique. (chaussette, bas ou collant)
                  <br /> 4. Sclérothérapie, souvent en complément par votre
                  phlébologue.
                  <br /> 5. Veinotoniques (controversé)
                  <br /> Parfois, ces méthodes médicales ne suffisent plus et il
                  faut traiter chirurgicalement les lésions.
                  <br />
                  <br />
                  <u> Le traitement chirurgical :</u>
                  <br /> Le principe est de supprimer le tronc de veine saphène
                  pathologique. On peut y arriver de 2 façons, soit en brûlant
                  la paroi de la veine par l’intérieur (techniques
                  endoveineuses), soit en retirant totalement la veine
                  (stripping ou éveinage)
                  <br />
                  <br />
                  <u>Techniques endoveineuses :</u> On réalise une ponction de
                  la veine saphène sous échographie{" "}
                  <button
                    onClick={() => {
                      setSchemaCommentCaSeSoigne(switchSchemas("schema2"));
                      schemaRef.current.scrollIntoView();
                    }}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    <u>(schéma TEV 1)</u>
                  </button>
                  , permettant de monter une sonde radiofréquence (ou fibre
                  laser) à l’intérieur de la veine{" "}
                  <button
                    onClick={() => {
                      setSchemaCommentCaSeSoigne(switchSchemas("schema3"));
                      schemaRef.current.scrollIntoView();
                    }}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    <u>(schéma TEV 1)</u>
                  </button>
                  . Après avoir réalisé une tumescence (infiltration sous la
                  peau d’un anesthésique dilué dans de l’eau), la veine est
                  brûlée sur tout son trajet, Cet échauffement entraîne la
                  fermeture du segment veineux traité{" "}
                  <button
                    onClick={() => {
                      setSchemaCommentCaSeSoigne(switchSchemas("schema4"));
                      schemaRef.current.scrollIntoView();
                    }}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    <u>(schéma TEV 3)</u>
                  </button>
                  . Il n’y a pas d’incision à l’aine ni dans le pli du genou.
                  C’est une technique maitrisée, confortable (très peu de
                  douleurs) et esthétique. <br />
                  <br />
                  <u>Stripping :</u>{" "}
                  <button
                    onClick={() => {
                      setSchemaCommentCaSeSoigne(switchSchemas("schema1"));
                      schemaRef.current.scrollIntoView();
                    }}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    <u>(schéma stripping)</u>
                  </button>{" "}
                  On introduit un stripper par deux incisions cutanées . La
                  veine est retirée en totalité. C’est le traitement de choix
                  pour les varices les plus importantes.
                  <br />
                  <br /> Ces deux interventions sont très fréquemment complétées
                  par l'ablation de toutes les petites veines variqueuses
                  inesthétiques (phlébectomies) qui ont pu se développer à
                  partir de la saphène. <br />
                  <br />
                  L'intervention est réalisée au bloc opératoire, sous
                  anesthésie locale ou générale. Les hospitalisations sont de
                  plus en plus brèves, souvent de quelques heures (ambulatoire).
                  Dans les suites de l'intervention des hématomes et ecchymoses
                  sensibles apparaissent, qui s'estomperont en deux à trois
                  semaines. La marche est reprise dès le lendemain de
                  l'intervention, mais un arrêt de travail est généralement
                  prescrit, la durée est fonction de l’activité professionnelle
                  et du choix de la technique.
                  <br /> La chirurgie peut être pratiquée à tout âge, hormis
                  pendant une grossesse, et en toute saison. Cependant il faut
                  savoir que le port de bas de contention est nécessaire pendant
                  deux à quatre semaines après l'opération et que cela est
                  forcément plus pénible pendant les périodes de grande chaleur.
                </p>
                <div ref={schemaRef}>{schemaCommentCaSeSoigne}</div>
                <img
                  src={upArrow}
                  alt=""
                  style={{
                    width: "50px",
                    height: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    /* cestquoiRef.current.scrollIntoView(); */
                    setShowCommentCaSeSoigne(false);
                  }}
                />
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
                marginTop: "10%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Comment cela se soigne ?
                </h3>
                <a href="#commentcasesoignevarices">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => setShowCommentCaSeSoigne(true)}
                  />
                </a>
              </div>
            </div>
          )}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Varices;
