import React from "react";
import { useMediaQuery } from "react-responsive";

import photo_chirurgie from "../assets/ope-1.png";

const Qualifications = () => {
  /*useEffect(() => {
    GApageView("landing");
  }, []);*/
  const isDesktopOrTablet = useMediaQuery({
    query: "(min-width: 601px)",
  });
  /* const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1371px)" }); */
  const isMobile = useMediaQuery({
    query: "(max-width: 600px)",
  });
  /* const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" }); */

  return (
    <div className="container-section">
      {isDesktopOrTablet && (
        <>
          <section
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <img
              src={photo_chirurgie}
              className="parallax"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: "-1",
                filter: `blur(3px)`,
              }}
              alt="intervention_dr_bourriez"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <h1 className="titre-section">Qualifications</h1>
              <div className="container-liens">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2%",
                    backgroundColor: "#0003",
                    color: "antiquewhite",
                    fontFamily: "Heebo",
                    fontSize: "150%",
                    textDecoration: "none",
                    borderBottomWidth: "20px",
                    borderBottomColor: "black",
                    cursor: "pointer",
                    margin: "2%",
                    boxShadow: "10px 5px 5px #0009",
                  }}
                  onClick={() =>
                    document.getElementById("qualifications").scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "nearest",
                    })
                  }
                >
                  Qualifications
                </p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2%",
                    backgroundColor: "#0003",
                    color: "antiquewhite",
                    fontFamily: "Heebo",
                    fontSize: "150%",
                    textDecoration: "none",
                    borderBottomWidth: "20px",
                    borderBottomColor: "black",
                    cursor: "pointer",
                    margin: "2%",
                    whiteSpace: "nowrap",
                    boxShadow: "10px 5px 5px #0009",
                  }}
                  onClick={() =>
                    document
                      .getElementById("formation-et-sociétés-savantes")
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                      })
                  }
                >
                  Formations{" & "}Sociétés savantes
                </p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2%",
                    backgroundColor: "#0003",
                    color: "antiquewhite",
                    fontFamily: "Heebo",
                    fontSize: "150%",
                    textDecoration: "none",
                    borderBottomWidth: "20px",
                    borderBottomColor: "black",
                    cursor: "pointer",
                    margin: "2%",
                    whiteSpace: "nowrap",
                    boxShadow: "10px 5px 5px #0009",
                  }}
                  onClick={() =>
                    document
                      .getElementById("travaux-et-publications")
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                      })
                  }
                >
                  Travaux et Publications
                </p>
              </div>
            </div>
          </section>

          <div
            style={{
              display: "flex",
              height: "3px",
              width: "70%",
              margin: "3%",
              background: "#333",
              overflow: "visible",
              alignItems: "center",
            }}
          >
            <h3
              id="qualifications"
              style={{
                display: "flex",
                color: "#333",
                marginLeft: "3%",
                backgroundColor: "#FFFFFF",
                padding: "0 8px 8px 8px",
                position: "absolute",
                fontFamily: "Heebo",
                fontSize: "calc(1vw + 1.5vh - 0.5vmin)",
              }}
            >
              Qualifications
            </h3>
          </div>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              lineHeight: "2rem",
              fontFamily: "Heebo",
              fontWeight: "bold",
              width: "63%",
              flexWrap: "nowrap",
            }}
          >
            <li
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexWrap: "nowrap",
                textAlign: "left",
              }}
            >
              • Médecin et Chirurgien, le Dr Armand Bourriez est qualifié
              Spécialiste en Chirurgie Vasculaire (inscription Ordre des
              Médecins)
            </li>
            <li>• Diplômé du Collège de Chirurgie Vasculaire</li>
            <li>• Diplôme Inter-Universitaire de Chirurgie Endovasculaire</li>
            <li>• Diplômé du D.E.S.C de Chirurgie Vasculaire</li>
            <li>• Diplômé du D.E.S de Chirurgie Générale</li>
            <li>
              • Ancien Praticien-Attaché à l'Hôpital Henri-Mondor (Service du
              Professeur Becquemin)
            </li>
            <li>
              • Ancien Chef de Clinique à la Faculté de Médecine de Paris{" "}
            </li>
            <li>
              • Ancien Assistant des Hôpitaux de Paris (Hôpital Ambroise Paré)
              1998-2000
            </li>
            <li>
              • Ancien Assistant des Hôpitaux de Paris (Hôpital Henri Mondor)
              2000-2002
            </li>
            <li>
              • Ancien Prosecteur d’anatomie à l’Ecole de chirurgie du Fer à
              Moulin (Paris)
            </li>
            <li>• Ancien Interne des Hôpitaux de Paris (1995-2000)</li>
            <li>• Titulaire d’une Maîtrise d’Anatomie</li>
          </ul>

          <div
            style={{
              display: "flex",
              height: "3px",
              width: "70%",
              margin: "3%",
              background: "#333",
              overflow: "visible",
              alignItems: "center",
            }}
          >
            <h3
              id="formation-et-sociétés-savantes"
              style={{
                display: "flex",
                color: "#333",
                marginLeft: "3%",
                backgroundColor: "#FFFFFF",
                padding: "0 8px 8px 8px",
                position: "absolute",
                fontFamily: "Heebo",
                fontSize: "calc(1vw + 1.5vh - 0.5vmin)",
              }}
            >
              Formations {"&"} Sociétés savantes
            </h3>
          </div>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              lineHeight: "2rem",
              fontFamily: "Heebo",
              fontWeight: "bold",
              width: "63%",
              flexWrap: "nowrap",
            }}
          >
            <li
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexWrap: "nowrap",
                textAlign: "left",
              }}
            >
              • Le Docteur Armand Bourriez a été formé en France, à Paris, dans
              les services de l’Assistance Publique Hôpitaux de Paris. Il est
              membre des sociétés savantes reconnues dans sa spécialité
            </li>
            <li>
              • Membre de la Société Française de Chirurgie Vasculaire et
              Endovasculaire (SCVE)
            </li>
            <li>
              • Membre de la Société Française des Abords Vasculaires (SFAV)
            </li>
            <li>• Membre du Collège de Chirurgie Vasculaire</li>
            <li>
              • Médecin accrédité par la Haute Autorité de Santé (HAS) depuis
              2008
            </li>
          </ul>
          {/* <a
            href="/CV_Armand_BOURRIEZ.pdf"
            download
            style={{
              display: "flex",
              color: "#faebd7",
              backgroundColor: "black",
              padding: "1%",
              borderRadius: "20px",
              margin: "4%",
            }}
          >
            Télécharger le CV
          </a> */}
          <div
            style={{
              display: "flex",
              height: "3px",
              width: "70%",
              margin: "3%",
              background: "#333",
              overflow: "visible",
              alignItems: "center",
            }}
          >
            <h3
              id="travaux-et-publications"
              style={{
                display: "flex",
                color: "#333",
                marginLeft: "3%",
                backgroundColor: "#FFFFFF",
                padding: "0 8px 8px 8px",
                position: "absolute",
                fontFamily: "Heebo",
                fontSize: "calc(1vw + 1.5vh - 0.5vmin)",
              }}
            >
              Travaux {"&"} Publications
            </h3>
          </div>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              lineHeight: "2rem",
              fontFamily: "Heebo",
              fontWeight: "bold",
              width: "63%",
              flexWrap: "nowrap",
            }}
          >
            <li
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexWrap: "nowrap",
                textAlign: "left",
              }}
            >
              • Le Dr Armand Bourriez est l'auteur de très nombreuses
              conférences et publications scientifiques dans des congrès et
              journaux spécialisés, français et étrangers (cf Titres et
              Travaux). Il participe chaque année depuis plus de 15 ans à de
              nombreuses conférences et symposium
            </li>
          </ul>
          <a
            href="/Titres_et_travaux.pdf"
            target="_blank"
            style={{
              display: "flex",
              color: "#faebd7",
              backgroundColor: "black",
              padding: "1%",
              borderRadius: "20px",
              margin: "4%",
            }}
          >
            Voir "Titres {"&"} Travaux"
          </a>
        </>
      )}
      {isMobile && (
        <>
          <section
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <img
              src={photo_chirurgie}
              className="parallax"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: "-1",
                filter: `blur(3px)`,
              }}
              alt="intervention_dr_bourriez"
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginBottom: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "300%",
                  fontFamily: "Heebo",
                  padding: "1.2%",
                  color: "antiquewhite",
                  backgroundColor: "#0003",
                }}
              >
                Qualifications
              </h1>
            </div>
          </section>

          <div
            style={{
              display: "flex",
              height: "3px",
              width: "70%",
              margin: "10%",
              background: "#333",
              overflow: "visible",
              alignItems: "center",
            }}
          >
            <h3
              id="qualifications"
              style={{
                display: "flex",
                color: "#333",
                marginLeft: "3%",
                backgroundColor: "#FFFFFF",
                padding: "0 8px 8px 8px",
                position: "absolute",
                fontFamily: "Heebo",
                fontSize: "200%",
              }}
            >
              Qualifications
            </h3>
          </div>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              lineHeight: "2rem",
              fontFamily: "Heebo",
              fontWeight: "bold",
              textAlign: "left",
              margin: "7%",
              marginTop: "0%",
            }}
          >
            <li>
              • Médecin et Chirurgien, le Dr Armand Bourriez est qualifié
              Spécialiste en Chirurgie Vasculaire (inscription Ordre des
              Médecins)
            </li>
            <li>• Diplômé du Collège de Chirurgie Vasculaire.</li>
            <li>• Diplôme Inter-Universitaire de Chirurgie Endovasculaire.</li>
            <li>• Diplômé du D.E.S.C de Chirurgie Vasculaire.</li>
            <li>• Diplômé du D.E.S de Chirurgie Générale.</li>
            <li>
              • Ancien Praticien-Attaché à l'Hôpital Henri-Mondor (Service du
              Professeur Becquemin)
            </li>
            <li>
              • Ancien Chef de Clinique à la Faculté de Médecine de Paris{" "}
            </li>
            <li>
              • Ancien Assistant des Hôpitaux de Paris (Hôpital Ambroise Paré)
              1998-2000
            </li>
            <li>
              • Ancien Assistant des Hôpitaux de Paris (Hôpital Henri Mondor)
              2000-2002
            </li>
            <li>
              • Ancien Prosecteur d’anatomie à l’Ecole de chirurgie du Fer à
              Moulin (Paris)
            </li>
            <li>• Ancien Interne des Hôpitaux de Paris (1995-2000).</li>
            <li>• Titulaire d’une Maîtrise d’Anatomie.</li>
          </ul>

          <div
            style={{
              display: "flex",
              height: "3px",
              width: "70%",
              margin: "10%",
              background: "#333",
              overflow: "visible",
              alignItems: "center",
            }}
          >
            <h3
              id="formation-et-sociétés-savantes"
              style={{
                display: "flex",
                color: "#333",
                marginLeft: "4%",
                backgroundColor: "#FFFFFF",
                padding: "0 8px 8px 8px",
                position: "absolute",
                fontFamily: "Heebo",
                fontSize: "200%",
              }}
            >
              Formations
            </h3>
          </div>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              lineHeight: "2rem",
              fontFamily: "Heebo",
              tWeight: "bold",
              textAlign: "left",
              margin: "7%",
              marginTop: "0%",
            }}
          >
            <li>
              • Le Docteur Armand Bourriez a été formé en France, à Paris, dans
              les services de l’Assistance Publique Hôpitaux de Paris. Il est
              membre des sociétés savantes reconnues dans sa spécialité.
            </li>
            <li>
              • Membre de la Société Française de Chirurgie Vasculaire et
              Endovasculaire (SCVE)
            </li>
            <li>
              • Membre de la Société Française des Abords Vasculaires (SFAV)
            </li>
            <li>• Membre du Collège de Chirurgie Vasculaire</li>
            <li>
              • Médecin accrédité par la Haute Autorité de Santé depuis 2008
            </li>
          </ul>
          <a
            href="/CV_Armand_BOURRIEZ.pdf"
            download
            style={{
              display: "flex",
              color: "#faebd7",
              backgroundColor: "black",
              padding: "5%",
              borderRadius: "20px",
              margin: "4%",
            }}
          >
            Télécharger le CV
          </a>
          <div
            style={{
              display: "flex",
              height: "3px",
              width: "70%",
              margin: "10%",
              background: "#333",
              overflow: "visible",
              alignItems: "center",
            }}
          >
            <h3
              id="travaux-et-publications"
              style={{
                display: "flex",
                color: "#333",
                marginLeft: "3%",
                backgroundColor: "#FFFFFF",
                padding: "0 8px 8px 8px",
                position: "absolute",
                fontFamily: "Heebo",
                fontSize: "150%",
              }}
            >
              Travaux {"&"} Publications
            </h3>
          </div>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              lineHeight: "2rem",
              fontFamily: "Heebo",
              fontWeight: "bold",
              textAlign: "left",
              margin: "7%",
              marginTop: "0%",
            }}
          >
            <li>
              • Le Dr Armand Bourriez est l'auteur de très nombreuses
              conférences et publications scientifiques dans des congrès et
              journaux spécialisés, français et étrangers (cf Titres et
              Travaux). Il participe chaque année depuis plus de 15 ans à de
              nombreuses conférences et symposium.
            </li>
          </ul>
          <a
            href="/titres_et_travaux_Dr_BOURRIEZ.pdf"
            download
            style={{
              display: "flex",
              color: "#faebd7",
              backgroundColor: "black",
              padding: "5%",
              borderRadius: "20px",
              margin: "4%",
            }}
          >
            Télécharger "Titres {"&"} Travaux"
          </a>
        </>
      )}
    </div>
  );
};

export default Qualifications;
