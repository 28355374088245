import React from "react";
import Flex from "./Flex";

const Place = (props) => {
  return (
    <Flex
      className="container"
      container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      color="#faebd7"
      width="50%"
    >
      <div
        className="infos"
        style={{ padding: "3%", backgroundColor: "black", borderRadius: "2%" }}
      >
        <h1
          style={{
            padding: "10%",
            fontSize: "150%",
            fontFamily: "Heebo",
            fontWeight: "bold",
          }}
        >
          {props.title}
        </h1>
        <h4
          style={{
            fontSize: "110%",
            fontFamily: "Heebo",
            fontWeight: "bold",
            margin: "4%",
          }}
        >
          {props.adress}
        </h4>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <a
            href="tel:{props.phone}"
            style={{
              padding: "5%",
              fontSize: "110%",
              fontFamily: "Heebo",
              fontWeight: "bold",
              color: "#faebd7",
              margin: "5%",
            }}
          >
            tél: {props.phone}
          </a>
          <a
            href={props.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#faebd7", margin: "5%" }}
          >
            {props.url}
          </a>
        </div>
      </div>
      <div
        className="map-and-picture"
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "5%",
          width: "100%",
        }}
      >
        <iframe
          title={props.title}
          src={props.mapSrc}
          width="800"
          height="550"
          frameborder="0"
          style={{ border: 0 }}
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </Flex>
  );
};

export default Place;
