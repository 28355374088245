import React, { useState, useRef } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import schemaImage1 from "../../assets/carotide/carotide1.jpg";
import schemaImage2 from "../../assets/carotide/carotide2.jpg";
import schemaImage3 from "../../assets/carotide/carotide3.jpg";
import schemaImage4 from "../../assets/carotide/carotide4.jpg";
import schemaImage5 from "../../assets/carotide/carotide5.jpg";
import downArrow from "../../assets/icons/down_arrow.png";
import upArrow from "../../assets/icons/up_arrow.png";

const Carotide = () => {
  /* useEffect(() => {
    GApageView("landing");
  }, []); */
  const [schemaCestQuoi, setSchemaCestQuoi] = useState(null);
  const [schemaCommentCaSeSoigne, setSchemaCommentCaSeSoigne] = useState(null);
  const [showCestQuoi, setShowCestQuoi] = useState(false);
  const [showEstCeGrave, setShowEstCeGrave] = useState(false);
  const [showCommentCaSeSoigne, setShowCommentCaSeSoigne] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const cestquoiRef = useRef(null);
  const cestquoiSchemaRef = useRef(null);

  const commentcasesoigneSchemaRef = useRef(null);

  function switchSchemas(schema) {
    switch (schema) {
      case "schema1":
        return (
          <img
            src={schemaImage1}
            alt="schema1"
            style={{
              display: "flex",
              flex: "1",
              width: "100%",
              height: "100%",
            }}
          />
        );

      case "schema2":
        return (
          <img
            src={schemaImage2}
            alt="schema2"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "schema3":
        return (
          <img
            src={schemaImage3}
            alt="schema3"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "schema4":
        return (
          <img
            src={schemaImage4}
            alt="schema4"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      case "schema5":
        return (
          <img
            src={schemaImage5}
            alt="schema5"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      /* case "FILM":
        return (
          <video controls width="400">
            <source src={filmMP4} type="video/mp4" />
            <source src={filmWeb} type="video/webm" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        ); */
      default:
        return <p></p>;
    }
  }

  return (
    <div className="container-section">
      <div
        style={{
          width: "100%",

          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "black",
        }}
      >
        <h1
          ref={cestquoiRef}
          style={{
            fontSize: "500%",
            fontFamily: "Heebo",
            color: "antiquewhite",
            margin: "6%",
          }}
        >
          Carotide
        </h1>
        <VerticalTimeline style={{ backgroundColor: "blue" }}>
          {showCestQuoi ? (
            <>
              <VerticalTimelineElement
                id="cestquoi"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "#000" }}
                contentArrowStyle={{ borderRight: "7px solid  #fff" }}
                iconStyle={{
                  background: "#faebd7",
                }}
              >
                <div>
                  <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                    De quoi s'agit-il ?
                  </h3>

                  <div>
                    <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                      Votre médecin vous a peut-être détecté une sténose
                      (rétrécissement) carotidienne. La carotide est l’une des
                      artères principales qui irrigue le cerveau{" "}
                      <button
                        onClick={() => {
                          setSchemaCestQuoi(switchSchemas("schema1"));
                          cestquoiSchemaRef.current.scrollIntoView();
                        }}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        <u>(schéma 1)</u>
                      </button>
                      . Il y a deux carotides, une droite et une gauche. Une
                      plaque d’athérome (accumulation de lipides, glucides,
                      sang, tissus adipeux, dépôts calcaires…) peut se
                      développer au niveau de la carotide{" "}
                      <button
                        onClick={() =>
                          setSchemaCestQuoi(switchSchemas("schema2"))
                        }
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        <u>(schéma 2)</u>
                      </button>
                      et être responsable d’un rétrécissement (sténose) ou d’une
                      obstruction (thrombose) qui peut perturber la circulation
                      dans l’artère et donc dans le cerveau.
                    </p>
                    <div ref={cestquoiSchemaRef}>{schemaCestQuoi}</div>
                    <img
                      src={upArrow}
                      alt=""
                      style={{
                        width: "50px",
                        height: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        /* cestquoiRef.current.scrollIntoView(); */
                        setShowCestQuoi(false);
                      }}
                    />
                  </div>
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                  marginLeft: "12%",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  De quoi s'agit-il ?
                </h3>
                <a href="#cestquoi">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowCestQuoi(true);
                    }}
                  />
                </a>
              </div>
            </div>
          )}

          {showEstCeGrave ? (
            <>
              <VerticalTimelineElement
                id="estcegrave"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <div>
                  <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                    Est-ce grave ?
                  </h3>
                  <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                    Être porteur d’une sténose carotidienne ne conduit pas
                    systématiquement à une intervention chirurgicale, c’est là
                    tout l’intérêt d’une consultation avec un chirurgien
                    vasculaire. L’un des risques de cette sténose carotidenne
                    est l’accident vasculaire cérébral (AVC) avec des troubles
                    neurologiques graves (paralysie, trouble de la vue, trouble
                    de l’élocution). Cependant, cet AVC est loin d’être
                    systématique et, c’est au décours de la consultation, après
                    analyse d’examens complémentaires que votre chirurgien
                    décidera d’une indication opératoire ou pas. Votre
                    chirurgien vasculaire vous expliquera tous les détails de
                    l’intervention, les risques et bénéfices de celle-ci.
                  </p>
                  <img
                    src={upArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowEstCeGrave(false);
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Est-ce grave ?
                </h3>
                <a href="#estcegrave">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => {
                      /* estcegraveRef.current.scrollIntoView(); */
                      setShowEstCeGrave(true);
                    }}
                  />
                </a>
              </div>
            </div>
          )}
          {showCommentCaSeSoigne ? (
            <>
              <VerticalTimelineElement
                id="commentcasesoigne"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <div>
                  <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                    Comment cela se soigne ?
                  </h3>

                  <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                    <u>- Le traitement médical :</u> Le premier traitement est
                    médical, il est indispensable, il est basé sur deux types de
                    médicaments, un médicament qui fluidifie le sang
                    (antiagrégant plaquettaire : ASPIRINE ou KARDÉGIC ou ASPÉGIC
                    ou PLAVIX) et les médicaments qui limitent l’évolution de la
                    plaque d’athérome : hypolipidémiants qui diminuent le taux
                    de cholestérol et de triglycérides dans le sang,
                    anti-diabétiques qui diminuent le taux de sucre dans le sang
                    et anti-hypertenseurs qui normalisent la tension artérielle.
                    Parfois, ces méthodes médicales ne suffisent plus et il faut
                    traiter chirurgicalement les lésions. <br />
                    <br />
                    <u>- Le traitement chirurgical :</u> Le but du traitement
                    chirurgical est d’enlever la plaque d’athérome, cela
                    s’appelle une endartériectomie. L’intervention consiste à
                    interrompre le flux sanguin et enlever la plaque d’athérome
                    <button
                      onClick={() => {
                        setSchemaCommentCaSeSoigne(switchSchemas("schema3"));
                        commentcasesoigneSchemaRef.current.scrollIntoView();
                      }}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <u>(schéma 3)</u>
                    </button>
                    . Une fois l’artère nettoyée de cette plaque{" "}
                    <button
                      onClick={() =>
                        setSchemaCommentCaSeSoigne(switchSchemas("schema4"))
                      }
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <u>(schéma 4)</u>
                    </button>
                    , le chirurgien referme l’artère par un patch synthétique
                    qui sera cousu avec du fil très fin{" "}
                    <button
                      onClick={() =>
                        setSchemaCommentCaSeSoigne(switchSchemas("schema5"))
                      }
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <u>(schéma 5)</u>
                    </button>
                    . L’hospitalisation dure en moyenne quatre jours. Voici un
                    film qui permet de voir le déroulement de l’intervention.
                    <button
                      /* onClick={() =>
                        setSchemaCommentCaSeSoigne(switchSchemas("FILM"))
                      } */
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      <a
                        href="https://youtu.be/GGm7DkWqkcE"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <u>(FILM)</u>
                      </a>
                    </button>
                    D’autres variantes techniques sont possibles et vous seront
                    expliquées par votre chirurgien.
                  </p>
                  <div ref={commentcasesoigneSchemaRef}>
                    {schemaCommentCaSeSoigne}
                  </div>
                  <img
                    src={upArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowCommentCaSeSoigne(false);
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "flex-start",
                marginTop: "10%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Comment cela se soigne ?
                </h3>
                <a href="#commentcasesoigne">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => {
                      setShowCommentCaSeSoigne(true);
                    }}
                  />
                </a>
              </div>
            </div>
          )}
          {showVideo ? (
            <>
              <VerticalTimelineElement
                id="video"
                className="vertical-timeline-element--work"
                contentStyle={{ background: "white", color: "##000" }}
                contentArrowStyle={{ borderRight: "8px solid  #fff" }}
                iconStyle={{ background: "#faebd7" }}
              >
                <div>
                  <h3 style={{ fontSize: "300%", fontFamily: "Heebo" }}>
                    Vidéo de l'opération
                  </h3>

                  <p style={{ fontFamily: "Heebo", fontWeight: "bold" }}>
                    Vous trouverez{" "}
                    <a
                      href="https://youtu.be/_xVgZGkl_i4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ici
                    </a>{" "}
                    une vidéo complète reprenant le parcours et l'opération d'un
                    patient atteint d'une défaillance carotidienne. De l'accueil
                    du patient à sa prise en charge post-opératoire, ce film
                    didactique dépeint une opération de la carotide sous toutes
                    ses coutures. <br />
                    <br /> Attention: ce film est un film chirurgical, il est
                    donc fait pour un public <u>averti</u>
                  </p>

                  <img
                    src={upArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      /* cestquoiRef.current.scrollIntoView(); */
                      setShowVideo(false);
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                }}
              >
                <h3
                  style={{
                    fontSize: "300%",
                    fontFamily: "Heebo",
                    color: "white",
                  }}
                >
                  Vidéo de l'opération
                </h3>
                <a href="#video">
                  <img
                    src={downArrow}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={() => {
                      /* estcegraveRef.current.scrollIntoView(); */
                      setShowVideo(true);
                    }}
                  />
                </a>
              </div>
            </div>
          )}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Carotide;
