import React from "react";
import "../style/Menus.css";
import clinique_europe from "../assets/clinique_europe.jpg";
import clinique_megival from "../assets/clinique_megival.jpg";
import clinique_saint_antoine from "../assets/clinique_saint_antoine.jpg";
import { useMediaQuery } from "react-responsive";

import { Map, TileLayer, Marker, Popup } from "react-leaflet";

const Contact = () => {
  /*useEffect(() => {
    GApageView("landing");
  }, []);*/
  const isDesktopOrTablet = useMediaQuery({
    query: "(min-width: 601px)",
  });

  /*const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1371px)" }); */
  const isMobile = useMediaQuery({
    query: "(max-width: 600px)",
  });
  /* const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" }); */
  return (
    <div>
      {isDesktopOrTablet && (
        <>
          <h1
            style={{
              display: "flex",
              fontSize: "500%",
              fontFamily: "Heebo",
              alignItems: "center",
              justifyContent: "center",
              margin: "5%",
              marginBottom: "1%",
            }}
          >
            Prendre rendez-vous
          </h1>
          <p
            style={{
              display: "flex",
              fontSize: "150%",
              fontFamily: "Heebo",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "-9%",
            }}
          >
            {" "}
            Afin de bénéficier d'une prise en charge personnalisée et optimale,
            les secrétaires sont à votre écoute et vous guideront dans votre
            prise de rendez-vous.
          </p>
          <section>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "10%",
                borderRadius: "50",
              }}
            >
              <Map
                center={[49.64471811329339, 1.2677584030192988]}
                zoom={9.46}
                scrollWheelZoom={false}
                style={{
                  display: "flex",
                  borderRadius: 50,
                  height: "600px",
                }}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[49.45791942960939, 1.1071087004599909]}>
                  <Popup>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        lineHeight: "-20%",
                      }}
                    >
                      <img
                        src={clinique_saint_antoine}
                        style={{
                          display: "flex",
                          width: "100%",
                          objectFit: "contain",
                          height: "auto",
                        }}
                        alt="clinique_europe"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            marginBottom: "-10%",
                            fontSize: "150%",
                          }}
                        >
                          Clinique Saint Antoine
                        </p>
                        <p>Contact Secrétariat</p>
                      </div>
                      <p
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <a href="tel:0235126040">02.35.12.60.40</a>

                        <a href="mailto:angio@clin-st-antoine.fr">
                          angio@clin-st-antoine.fr
                        </a>
                      </p>
                    </div>
                  </Popup>
                </Marker>
                <Marker position={[49.42593971975097, 1.086153656279641]}>
                  <Popup
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "black",
                      color: "antiquewhite",
                      fontFamily: "Heebo",
                      width: "100%",
                      fontWeight: "bold",
                      fontSize: "120%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={clinique_europe}
                        style={{
                          display: "flex",
                          width: "100%",
                          objectFit: "contain",
                          height: "auto",
                        }}
                        alt="clinique_europe"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            marginBottom: "-10%",
                            fontSize: "150%",
                          }}
                        >
                          Clinique de l'Europe
                        </p>
                        <p>Contact Secrétariat</p>
                      </div>
                      <p
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <a href="tel:0232181276">02.32.18.12.76</a>

                        <a href="mailto:sec.abourriez@vivalto-sante.com">
                          sec.abourriez@vivalto-sante.com
                        </a>
                      </p>
                    </div>
                  </Popup>
                </Marker>
                <Marker position={[49.895650371980324, 1.0790956139644765]}>
                  <Popup>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={clinique_megival}
                        style={{
                          display: "flex",
                          width: "100%",
                          objectFit: "contain",
                          height: "auto",
                        }}
                        alt="clinique_europe"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            marginBottom: "-10%",
                            fontSize: "150%",
                          }}
                        >
                          Clinique Mégival
                        </p>
                        <p>Contact Secrétariat</p>
                      </div>

                      <p
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <a href="tel:0276203085">02.76.20.30.85</a>
                        <a href="mailto:secretariat.vasculaire@megival.fr">
                          secretariat.vasculaire@megival.fr
                        </a>
                      </p>
                      <p></p>
                    </div>
                  </Popup>
                </Marker>
              </Map>
            </div>
          </section>
        </>
      )}
      {isMobile && (
        <>
          <h1
            style={{
              display: "flex",
              fontSize: "300%",
              fontFamily: "Heebo",
              alignItems: "center",
              justifyContent: "center",
              margin: "10%",
              marginBottom: "1%",
            }}
          >
            Prendre <br />
            rendez-vous
          </h1>
          <p
            style={{
              display: "flex",
              fontSize: "100%",
              fontFamily: "Heebo",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "-9%",
              textAlign: "justify",
              margin: "6%",
            }}
          >
            {" "}
            Afin de bénéficier d'une prise en charge personnalisée et optimale,
            les secrétaires sont à votre écoute et vous guideront dans votre
            prise de rendez-vous.
          </p>
          <section>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "10%",
                borderRadius: "50",
              }}
            >
              <Map
                center={[49.49842113920539, 1.102615755837982]}
                zoom={9.4}
                scrollWheelZoom={false}
                style={{
                  display: "flex",
                  borderRadius: 50,
                  height: "600px",
                  zIndex: 1,
                }}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[49.45791942960939, 1.1071087004599909]}>
                  <Popup>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        lineHeight: "-20%",
                      }}
                    >
                      <img
                        src={clinique_saint_antoine}
                        style={{
                          display: "flex",
                          width: "100%",
                          objectFit: "contain",
                          height: "auto",
                        }}
                        alt="clinique_europe"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            marginBottom: "-10%",
                            fontSize: "150%",
                          }}
                        >
                          Clinique Saint Antoine
                        </p>
                        <p>Contact Secrétariat</p>
                      </div>
                      <p
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <a href="tel:0235126040">02.35.12.60.40</a>

                        <a href="mailto:angio@clin-st-antoine.fr">
                          angio@clin-st-antoine.fr
                        </a>
                      </p>
                    </div>
                  </Popup>
                </Marker>
                <Marker position={[49.42593971975097, 1.086153656279641]}>
                  <Popup
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "black",
                      color: "antiquewhite",
                      fontFamily: "Heebo",
                      width: "100%",
                      fontWeight: "bold",
                      fontSize: "120%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={clinique_europe}
                        style={{
                          display: "flex",
                          width: "100%",
                          objectFit: "contain",
                          height: "auto",
                        }}
                        alt="clinique_europe"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            marginBottom: "-10%",
                            fontSize: "150%",
                          }}
                        >
                          Clinique de l'Europe
                        </p>
                        <p>Contact Secrétariat</p>
                      </div>
                      <p
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <a href="tel:0232181276">02.32.18.12.76</a>

                        <a href="mailto:sec.abourriez@vivalto-sante.com">
                          sec.abourriez@vivalto-sante.com
                        </a>
                      </p>
                    </div>
                  </Popup>
                </Marker>
                <Marker position={[49.895650371980324, 1.0790956139644765]}>
                  <Popup>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={clinique_megival}
                        style={{
                          display: "flex",
                          width: "100%",
                          objectFit: "contain",
                          height: "auto",
                        }}
                        alt="clinique_europe"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            marginBottom: "-10%",
                            fontSize: "150%",
                          }}
                        >
                          Clinique Mégival
                        </p>
                        <p>Contact Secrétariat</p>
                      </div>

                      <p
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <a href="tel:0276203085">02.76.20.30.85</a>
                        <a href="mailto:secretariat.vasculaire@megival.fr">
                          secretariat.vasculaire@megival.fr
                        </a>
                      </p>
                      <p></p>
                    </div>
                  </Popup>
                </Marker>
              </Map>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default Contact;
