import React from "react";

const MentionsLegales = () => {
  return (
    <div className="container-section">
      <section className="intervention-title">
        <h1 style={{ fontSize: "500%", fontFamily: "Heebo", padding: "2%" }}>
          Mentions légales
        </h1>
        <p
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "5%",
            textAlign: "left",
          }}
        >
          <u>Rédacteur / Auteur du site:</u> Ce site internet est le site
          professionnel du Docteur Armand Bourriez, médecin et chirurgien,
          spécialiste en chirurgie vasculaire, inscrit au Conseil de l’Ordre des
          médecins de Paris sous le numéro 75/71001. Le Docteur Armand Bourriez
          est l’auteur, le rédacteur et le seul responsable du contenu et de la
          présentation de son site professionnel.
          <br />
          <br />
          <u>Complémentarité:</u> Le site professionnel du Docteur Armand
          Bourriez a pour objet d’apporter des informations et des services
          pratiques à ses patients et futurs patients. Ces informations et
          services pratiques permettent de soutenir et compléter la relation du
          Docteur Armand Bourriez avec ses patients, sans la remplacer.
          <br />
          <br />
          <u>Mission du site:</u> Les 3 missions du site professionnel pour les
          visiteurs — et en particulier ses patients — sont de:
          <br /> - prendre connaissance de sa formation, de ses titres, de ses
          qualifications, et de ses travaux
          <br /> - de permettre aux visiteurs de connaitre les domaines
          d’activités du Docteur Armand Bourriez
          <br /> - de retrouver les informations pratiques comme l’adresse des
          lieux de consultations, d’interventions, les coordonnées du
          secrétariat et les plans d’accès.
          <br />
          <br />
          <u>Audience du site:</u> Le site professionnel du Docteur Armand
          Bourriez s’adresse à un public général et à ses patients en
          particulier.
          <br />
          <br /> <u>Confidentialité et Respect de la vie privée:</u> Les
          adresses email et les informations personnelles et médicales ne sont
          en aucun cas collectés sur le site professionnel du Docteur Armand
          Bourriez. Elles ne font l’objet d’aucun traitement informatique et en
          particulier d’aucun stockage dans un fichier informatique. Les seules
          informations à même d'être utlisées sont les cookies servant à
          analyser et améliorer les performances du site (notamment avec Google
          Analytics).
          <br />
          <br />
          <u>Origine, Référence et Date de l’information:</u> Le site internet
          professionnel du Docteur Armand Bourriez a été actualisé le 5 Janvier
          2021 (dernière mise à jour), par son webmaster Nicolas Bourriez.
          Toutes les informations présentées sur le site proviennent
          exclusivement du Docteur Armand Bourriez. Aucune information ne
          provient d'une source exterieure.
          <br />
          <br />
          <u>Justification des déclaration:</u> Aucune information concernant la
          performance d’un traitement médical, d’un produit commercial ou d’un
          service n’est présentée sur le site internet professionnel du Docteur
          Armand Bourriez car ce n’est pas la vocation de ce site. <br />
          <br />
          <u>Contact:</u> Toute demande de contact avec l’auteur et le
          responsable de ce site doit être adressée par email au Docteur Armand
          Bourriez (armand.bourriez@clinique-europe.com).
          <br />
          <br />
          <u>Financement:</u> Ce site professionnel ne bénéficie d’aucune source
          de financement externe. Il est entièrement financé par le Docteur
          Armand Bourriez. <br />
          <br />
          <u>Politique publicitaire:</u> Aucune publicité n’est présentée sur ce
          site internet.
        </p>
      </section>
    </div>
  );
};

export default MentionsLegales;
