import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/logo_final.png";
import { slide as Menu } from "react-burger-menu";
import { useMediaQuery } from "react-responsive";
import styled, { keyframes } from "styled-components";
import { fadeInDown } from "react-animations";

import "../style/NavBar.css";
import { MyContext } from "../App.js";

const FadeInDown = styled.div`
  animation: 1.5s ${keyframes`${fadeInDown}`};
`;

const Navigation = styled.body`
width: 100%;
position: relative;
z-index: 1;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
align-items: center;
height: 110px;
background: #000;

.dr-bourriez a {
  
  padding-top: 20px;
  opacity: 1.5;
  display: flex;
  flex-direction: column;
  clear: both;
  color: #faebd7;
  padding-bottom: 20px;
  text-decoration: none;

  h3 {
    margin-left: 50%;
    width: 160%;
    justify-content: center;
    display: block;
    
  }
}

.fa-bars {
  display: none;
  color: #faebd7;
  font-size: 5rem;
}
.fas {
  display: none;
  color: #faebd7;
  font-size: 2rem;
}

nav {
  display:flex;
  margin-right:5%;
  ul {
    display: flex;
    justify-content: space-between;
  }
  li {
    justify-content: space-between;
    font-size: 1em;
  }

  a {
    color: #faebd7;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0.6;
    font-size: 1em;
    margin: 0 30px ;
    padding: 0;
    transition: all 0.2s ease-in-out;
    position: relative;
    text-decoration: none;
  }

  a::after {
    content: "";
    position: absolute;
    bottom: -10px;
    width: 0px;
    height: 1px;
    margin: 5px 0 0;
    transition: all 0.2s ease-in-out;
    transition-duration: 0.5s;
    opacity: 0;
    background-color: #faebd7;
    left:0
  }

  a:hover {
    cursor: pointer;
    opacity: 1.5;
  }

  a:hover::after {
      width: 100%;
      opacity: 1;
  }
  
  a.active {
    opacity: 1.5;
  }

  .menu {
    position: absolute;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;

    .interventions-list {
      display:flex;
      flex-wrap: nowrap;
      flex-direction: column;
      background-color: black;
    

      a {
        color: #faebd7;
        justify-content: center;
        align-items: center;
        text-align: center;
        opacity: 0.6;
        font-size: 1em;
        margin: 0 auto ;
        padding: 12px;
        position: relative;
        text-decoration: none;
      }
      a::after{
        content: "";
        position: absolute;
        bottom: 2px;
        width: 0px;
        height: 1px;
        margin: 5px 0 0;
        transition: all 0.2s ease-in-out;
        transition-duration: 0.5s;
        opacity: 0;
        background-color: #faebd7;
        left:0
      };
      a:hover {
        cursor: pointer;
        opacity: 1.5;
        
      }
      a.active{
        opacity:1.5;
      }
    }
  }

  .menu-2 {
    position: absolute;
    justify-content: center;
    align-items: center;
    right: 2%;
    .cliniques-list {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: black;
        width: 130%;
        a {
          color: #faebd7;
          justify-content: center;
          align-items: center;
          text-align: center;
          right: 10%;
          width: 100%;
          opacity: 0.6;
          font-size: 1em;
          margin: 0 auto ;
          padding: 20px;
          position: relative;
          text-decoration: none;
        }
        a::after{
          content: "";
          position: absolute;
          bottom: 2px;
          width: 0px;
          height: 1px;
          margin: 5px 0 0;
          transition: all 0.2s ease-in-out;
          transition-duration: 0.5s;
          opacity: 0;
          background-color: #faebd7;
          left:0
        };
        a:hover {
          cursor: pointer;
          opacity: 1.5;
        }
      }
    }
  }
}
}

@media only screen and (max-width: 500px) {
  height: auto;
  min-height: 30px;
  display: block;
  width: auto;
  justify-content: space-between;
  position: relative;
  background-color: black;
  .dr-bourriez {
    width: 100%;
    display: flex;
    justify-content: start;
    margin: 0px;
    margin-left: -15%;
    a {
      padding: 15px 0px;
    }
  }
  #logo_ab{
    display: flex;
    width: 10%;
    object-fit: contain;
    
    height: 10%;
    margin-left:-20%
  }
  ul.collapsed {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

    overflow: hidden;
    max-height: 0;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

    &.is-expanded {
      overflow: hidden;
      max-height: 500px; /* approximate max height */
      -moz-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -moz-transition-timing-function: ease-in;
      -webkit-transition-timing-function: ease-in;
      -o-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
    }
    li {
      padding: 15px 1px;
      margin: 0 0 0;
      width: 100%;
    }
    a {
      color: #faebd7;
      justify-content: center;
      align-items: center;
      text-align: center;
      opacity: 0.6;
      font-size: 1em;
      margin: 0 auto ;
      padding: 0;
      position: relative;
      text-decoration: none;
    }

    a::after {
      content: "";
      position: absolute;
      bottom: 7px;
      width: 0px;
      height: 1px;
      margin: 5px 0 0;
      opacity: 0;
      background-color: #faebd7;
      left:0
    }
  
   a:hover {
      cursor: pointer;
      opacity: 0;
    }

    a:hover::after {
        width: 0%;
        opacity: 0;
    }
  
    a:active {
      opacity: 1;
    }
  }
}
`;

const NavBar = ({ test, element }) => {
  const [isShown, setIsShown] = useState(false);
  const [CliniquesisShown, setCliniquesIsShown] = useState(false);

  const ctx = useContext(MyContext);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1372px)",
  });
  /* const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" }); */
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1371px)" });
  /* const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" }); */

  return (
    <div>
      {isDesktopOrLaptop && (
        <>
          <Navigation>
            <div className="dr-bourriez">
              <a
                href="https://dr-bourriez.fr"
                style={{ display: "flex", width: "75%" }}
              >
                <img
                  id="logo_ab"
                  src={logo}
                  style={{ display: "flex", width: "20%", marginLeft: "20%" }}
                  alt="logo"
                />
              </a>
            </div>
            <nav className="nav">
              <ul className="">
                <NavLink activeClassName="active" to="/about">
                  <li className="features">Présentation</li>
                </NavLink>
                <NavLink activeClassName="active" to="/qualifications">
                  <li className="features">Qualifications</li>
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to="/interventions"
                  onMouseEnter={() => setIsShown(true)}
                  onMouseLeave={() => setIsShown(false)}
                >
                  <li className="features">Interventions</li>
                  {isShown && (
                    <FadeInDown>
                      <div className="menu">
                        <ul className="interventions-list">
                          <NavLink
                            activeClassName="active"
                            to="/interventions/anevrisme"
                          >
                            <li>Anévrisme aortique</li>
                          </NavLink>
                          <NavLink
                            activeClassName="active"
                            to="/interventions/carotide"
                          >
                            <li>Carotide </li>
                          </NavLink>
                          <NavLink
                            activeClassName="active"
                            to="/interventions/arterite"
                          >
                            <li>Artérite (artère osbtruée)</li>
                          </NavLink>
                          <NavLink
                            activeClassName="active"
                            to="/interventions/fistule"
                          >
                            <li>Fistule artério-veineuse (dialyse)</li>
                          </NavLink>
                          <NavLink
                            activeClassName="active"
                            to="/interventions/varices"
                          >
                            <li>Varices</li>
                          </NavLink>
                        </ul>
                      </div>
                    </FadeInDown>
                  )}
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to="/cliniques"
                  onMouseEnter={() => setCliniquesIsShown(true)}
                  onMouseLeave={() => setCliniquesIsShown(false)}
                >
                  <li className="features">Cliniques</li>
                  {CliniquesisShown && (
                    <FadeInDown>
                      <div className="menu-2">
                        <ul className="cliniques-list">
                          <NavLink
                            activeClassName="active"
                            to="/cliniques/clinique-de-l-europe"
                          >
                            <li>Clinique de l'Europe (Rouen)</li>
                          </NavLink>
                          <NavLink
                            activeClassName="active"
                            to="/cliniques/clinique-megival"
                          >
                            <li>
                              Clinique Mégival <br />
                              (Dieppe){" "}
                            </li>
                          </NavLink>
                          <NavLink
                            activeClassName="active"
                            to="/cliniques/clinique-saint-antoine"
                          >
                            <li>Clinique Saint Antoine (Bois-Guillaume)</li>
                          </NavLink>
                        </ul>
                      </div>
                    </FadeInDown>
                  )}
                </NavLink>
                <NavLink activeClassName="active" to="/contact">
                  <li className="features">Prendre rendez-vous</li>
                </NavLink>
              </ul>
            </nav>
          </Navigation>
        </>
      )}
      {isTabletOrMobile && (
        <>
          <Navigation style={{ zIndex: 2000 }}>
            <div className="dr-bourriez">
              <a
                href="https://dr-bourriez.fr"
                style={{
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <img
                  id="logo_ab"
                  src={logo}
                  style={{
                    display: "flex",
                    width: "20%",
                    height: "85%",
                    marginLeft: "25%",
                  }}
                  alt="logo"
                />
              </a>
            </div>
            <Menu
              right
              disableAutoFocus
              isOpen={ctx.isMenuOpen}
              onStateChange={(state) => ctx.stateChangeHandler(state)}
              width={"65%"}
              style={{ zIndex: 2000 }}
            >
              <NavLink
                style={{ display: "flex" }}
                to="/about"
                onClick={ctx.toggleMenu}
              >
                <p>Présentation</p>
              </NavLink>
              <NavLink
                style={{ display: "flex", flexDirection: "column" }}
                to="/qualifications"
                onClick={ctx.toggleMenu}
              >
                <p>Qualifications</p>
              </NavLink>
              <NavLink to="/interventions" onClick={ctx.toggleMenu}>
                <p>Interventions</p>
              </NavLink>
              <NavLink to="/cliniques" onClick={ctx.toggleMenu}>
                <p>Cliniques</p>
              </NavLink>
              <NavLink to="/contact" onClick={ctx.toggleMenu}>
                <p>Contacts</p>
              </NavLink>
            </Menu>
          </Navigation>
        </>
      )}
    </div>
  );

  /*
<Navigation>
      <div className="dr-bourriez">
        <img
          id="logo_ab"
          src={logo}
          style={{ display: "flex", width: "20%", marginLeft: "20%" }}
          alt="logo"
        /> 
      </div>
      <nav className="nav">
        {isExpanded ? (
          <i
            class="fas fa-times"
            aria-hidden="true"
            onClick={(e) => handleToggle(e)}
          ></i>
        ) : (
          <i
            className="fas fa-bars"
            aria-hidden="true"
            onClick={(e) => handleToggle(e)}
          />
        )}
        <ul className={`collapsed ${isExpanded ? "is-expanded" : ""}`}>
          <NavLink activeClassName="active" to="/">
            <li className="features">Présentation</li>
          </NavLink>
          <NavLink activeClassName="active" to="/qualifications">
            <li className="features">Qualifications</li>
          </NavLink>
          <NavLink
            activeClassName="active"
            to="/interventions"
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
          >
            <li className="features">Interventions</li>
            {isShown && (
              <div className="menu">
                <ul className="interventions-list">
                  <NavLink
                    activeClassName="active"
                    to="/interventions/anevrisme"
                  >
                    <li>Anévrisme aortique</li>
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/interventions/carotide"
                  >
                    <li>Carotide </li>
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/interventions/arterite"
                  >
                    <li>Artérite (artère osbtruée)</li>
                  </NavLink>
                  <NavLink activeClassName="active" to="/interventions/fistule">
                    <li>Fistule artério-veineuse (dialyse)</li>
                  </NavLink>
                  <NavLink activeClassName="active" to="/interventions/varices">
                    <li>Varices</li>
                  </NavLink>
                </ul>
              </div>
            )}
          </NavLink>
          <NavLink
            activeClassName="active"
            to="/cliniques"
            onMouseEnter={() => setCliniquesIsShown(true)}
            onMouseLeave={() => setCliniquesIsShown(false)}
          >
            <li className="features">Cliniques</li>
            {CliniquesisShown && (
              <div className="menu-2">
                <ul className="cliniques-list">
                  <NavLink
                    activeClassName="active"
                    to="/cliniques/clinique-de-l-europe"
                  >
                    <li>Clinique de l'Europe (Rouen)</li>
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/cliniques/clinique-megival"
                  >
                    <li>
                      Clinique Mégival <br />
                      (Dieppe){" "}
                    </li>
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/cliniques/clinique-saint-antoine"
                  >
                    <li>Clinique Saint Antoine (Bois-Guillaume)</li>
                  </NavLink>
                </ul>
              </div>
            )}
          </NavLink>
          <NavLink activeClassName="active" to="/contact">
            <li className="features">Prendre rendez-vous</li>
          </NavLink>
        </ul>
      </nav>
    </Navigation> */
};

export default NavBar;
