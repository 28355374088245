import React from "react";
import "../../style/Cliniques.css";
import Place from "../../components/Place";
import styled, { keyframes } from "styled-components";

import photo_saint_antoine from "../../assets/clinique_saint_antoine.jpg";
import { fadeIn } from "react-animations";

const FadeIn = styled.div`
  animation: 4s ${keyframes`${fadeIn}`};
`;

const SaintAntoine = () => {
  /* useEffect(() => {
    GApageView("landing");
  }, []); */
  return (
    <div className="container-section">
      <section
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={photo_saint_antoine}
          className="parallax"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: "-1",
            filter: `blur(2px)`,
          }}
          alt="portrait_docteur_bourriez"
        />
        <h1 className="titre-section">Clinique Saint Antoine</h1>
      </section>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "90%",
        }}
      >
        <h1
          style={{
            display: "flex",
            fontSize: "300%",
            fontFamily: "Heebo",
            margin: "2.5%",
          }}
        >
          À propos
        </h1>
        <FadeIn className="description-clinique">
          <h4>
            La Clinique Saint-Antoine est un établissement familial, idéal pour
            la réalisation des varices en ambulatoire.
            <br />
            <br />
            Etablissement pluridisciplinaire certifié par la H.A.S, il est
            composé d’une équipe médicale et paramédicale complémentaire (50
            salariés et 30 praticiens). <br />
            <br />
            L’établissement est équipé de 48 lits médicalisés en hospitalisation
            complète et 19 places installées en ambulatoire disposant chacune
            d’une salle de bain ou salle d’eau ou de toilettes personnel.
          </h4>
        </FadeIn>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "120%",
          padding: "4%",
        }}
      >
        <Place
          title="Clinique Saint Antoine"
          adress="696 Rue Robert Pinchon, 76230 Bois-Guillaume"
          phone="02 35 12 60 00"
          src={photo_saint_antoine}
          url={"https://cliniquesaintantoine.vivalto-sante.com/"}
          mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41494.63983741413!2d1.0720146768536376!3d49.45777297935072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e0dc34685c7085%3A0x96cba61fa5fb0883!2sClinique%20Saint-Antoine!5e0!3m2!1sfr!2sfr!4v1600085345228!5m2!1sfr!2sfr"
        />
      </div>
    </div>
  );
};

export default SaintAntoine;
