import React from "react";
import "../../style/Menus.css";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import { useMediaQuery } from "react-responsive";

const FadeIn = styled.div`
  animation: 5s ${keyframes`${fadeIn}`};
  color: black;
`;

const CliniquesMenu = () => {
  const isDesktopOrTablet = useMediaQuery({
    query: "(min-width: 601px)",
  });
  /* const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1371px)" }); */
  const isMobile = useMediaQuery({
    query: "(max-width: 600px)",
  });
  /* const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" }); */
  return (
    <div>
      {isDesktopOrTablet && (
        <>
          <h1
            style={{
              display: "flex",
              fontSize: "500%",
              fontFamily: "Heebo",
              alignItems: "center",
              justifyContent: "center",
              margin: "5%",
            }}
          >
            Cliniques
          </h1>
          <div className="container-liens">
            <FadeIn className="liens-menu">
              <Link
                style={{
                  color: "black",
                  fontFamily: "Heebo",
                  fontSize: "140%",
                  textDecoration: "none",
                  borderBottomWidth: "20px",
                  borderBottomColor: "black",
                  padding: "0.5%",
                  margin: "3%",
                  whiteSpace: "nowrap",
                  boxShadow: "10px 5px 5px #0001",
                }}
                to="/cliniques/clinique-de-l-europe"
              >
                Clinique de l'Europe
              </Link>
            </FadeIn>
            <FadeIn className="liens-menu">
              <Link
                style={{
                  color: "black",
                  fontFamily: "Heebo",
                  fontSize: "140%",
                  textDecoration: "none",
                  borderBottomWidth: "20px",
                  borderBottomColor: "black",
                  padding: "0.5%",
                  margin: "3%",
                  whiteSpace: "nowrap",
                  boxShadow: "10px 5px 5px #0001",
                }}
                to="/cliniques/clinique-megival"
              >
                Clinique Mégival
              </Link>
            </FadeIn>
            <FadeIn className="liens-menu">
              <Link
                style={{
                  color: "black",
                  fontFamily: "Heebo",
                  fontSize: "140%",
                  textDecoration: "none",
                  borderBottomWidth: "20px",
                  borderBottomColor: "black",
                  padding: "0.5%",
                  margin: "3%",
                  whiteSpace: "nowrap",
                  boxShadow: "10px 5px 5px #0001",
                }}
                to="/cliniques/clinique-saint-antoine"
              >
                Clinique Saint Antoine
              </Link>
            </FadeIn>
          </div>
        </>
      )}
      {isMobile && (
        <>
          <h1
            style={{
              display: "flex",
              fontSize: "500%",
              fontFamily: "Heebo",
              alignItems: "center",
              justifyContent: "center",
              margin: "5%",
            }}
          >
            Cliniques
          </h1>
          <div className="container-liens">
            <FadeIn className="liens-menu">
              <Link
                style={{
                  color: "black",
                  fontFamily: "Heebo",
                  fontSize: "140%",
                  textDecoration: "none",
                  borderBottomWidth: "20px",
                  borderBottomColor: "black",
                  padding: "0.5%",
                  margin: "3%",
                  whiteSpace: "nowrap",
                  boxShadow: "10px 5px 5px #0001",
                }}
                to="/cliniques/clinique-de-l-europe"
              >
                Clinique de l'Europe
              </Link>
            </FadeIn>
            <FadeIn className="liens-menu">
              <Link
                style={{
                  color: "black",
                  fontFamily: "Heebo",
                  fontSize: "140%",
                  textDecoration: "none",
                  borderBottomWidth: "20px",
                  borderBottomColor: "black",
                  padding: "0.5%",
                  margin: "3%",
                  whiteSpace: "nowrap",
                  boxShadow: "10px 5px 5px #0001",
                }}
                to="/cliniques/clinique-megival"
              >
                Clinique Mégival
              </Link>
            </FadeIn>
            <FadeIn className="liens-menu">
              <Link
                style={{
                  color: "black",
                  fontFamily: "Heebo",
                  fontSize: "140%",
                  textDecoration: "none",
                  borderBottomWidth: "20px",
                  borderBottomColor: "black",
                  padding: "0.5%",
                  margin: "3%",
                  whiteSpace: "nowrap",
                  boxShadow: "10px 5px 5px #0001",
                }}
                to="/cliniques/clinique-saint-antoine"
              >
                Clinique Saint Antoine
              </Link>
            </FadeIn>
          </div>
        </>
      )}
    </div>
  );
};

export default CliniquesMenu;
