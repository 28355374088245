import React from "react";
import ReactDOM from "react-dom";
import "./style/index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import Analytics from "react-router-ga";
import * as serviceWorker from "./serviceWorker";
/* import ReactGA from "react-ga"; */

/* export const initGA = () => {
  ReactGA.initialize("G-9S09YS95XW"); // put your tracking id here
};

export const GApageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
}; */

ReactDOM.render(
  <Router>
    <Analytics id="G-9S09YS95XW" debug>
      <App />
    </Analytics>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
