import React from "react";
import "../../style/Cliniques.css";
import Place from "../../components/Place";
import styled, { keyframes } from "styled-components";

import photo_megival from "../../assets/clinique_megival.jpg";
import { fadeIn } from "react-animations";

const FadeIn = styled.div`
  animation: 4s ${keyframes`${fadeIn}`};
`;
const Megival = () => {
  /* useEffect(() => {
    GApageView("landing");
  }, []); */
  return (
    <div className="container-section">
      <section
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={photo_megival}
          className="parallax"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: "-1",
            filter: `blur(2px)`,
          }}
          alt="portrait_docteur_bourriez"
        />
        <h1 className="titre-section">Clinique Mégival</h1>
      </section>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "90%",
        }}
      >
        <h1
          style={{
            display: "flex",
            fontSize: "300%",
            fontFamily: "Heebo",
            margin: "2.5%",
          }}
        >
          À propos
        </h1>
        <FadeIn className="description-clinique">
          <h4>
            La Clinique Mégival offre un plateau technique très satisfaisant
            pour toute la chirurgie vasculaire hormis la chirurgie aortique et
            carotidienne. -
          </h4>
        </FadeIn>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "120%",
          padding: "4%",
        }}
      >
        <Place
          title="Clinique Mégival"
          adress="1328 Avenue de la Maison Blanche, 76550 Saint-Aubin-sur-Scie"
          phone="02 76 20 30 40"
          src={photo_megival}
          url={"https://megival.com/"}
          mapSrc="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10280.696775197717!2d1.0790527!3d49.8955329!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xedb9481c55d605ab!2sClinique%20M%C3%A9gival!5e0!3m2!1sfr!2sfr!4v1600082312935!5m2!1sfr!2sfr"
        />
      </div>
    </div>
  );
};

export default Megival;
