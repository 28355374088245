import React, { useState } from "react";
import { Route } from "react-router-dom";
import "./style/App.css";

import NavBar from "./components/NavBar";

import Presentation from "./screens/Presentation";
import Qualifications from "./screens/Qualifications";
import Contact from "./screens/Contact";

import Anevrisme from "./screens/Interventions/Anevrisme";
import Carotide from "./screens/Interventions/Carotide";
import Arterite from "./screens/Interventions/Arterite";
import Fistule from "./screens/Interventions/Fistule";
import Varices from "./screens/Interventions/Varices";
import Europe from "./screens/Cliniques/Europe";
import Megival from "./screens/Cliniques/Megival";
import SaintAntoine from "./screens/Cliniques/SaintAntoine";
import CliniquesMenu from "./screens/Cliniques/CliniquesMenu";
import InterventionsMenu from "./screens/Interventions/InterventionsMenu";
import MentionsLegales from "./screens/MentionsLegales";

// make a new context
export const MyContext = React.createContext();

// create the provider
const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);
  /* useEffect(() => {
    initGA();
  }, []); */
  return (
    <MyContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

function App() {
  return (
    <MyProvider>
      <div className="App">
        <div className="container">
          <NavBar />
          <Route exact={true} path="/" component={Presentation} />
          <Route exact={true} path="/about" component={Presentation} />
          <Route exact={true} path="/cliniques" component={CliniquesMenu} />
          <Route
            exact={true}
            path="/interventions"
            component={InterventionsMenu}
          />
          <Route exact path="/qualifications" component={Qualifications} />
          <Route
            exact
            path="/cliniques/clinique-de-l-europe"
            component={Europe}
          />
          <Route exact path="/cliniques/clinique-megival" component={Megival} />
          <Route
            exact
            path="/cliniques/clinique-saint-antoine"
            component={SaintAntoine}
          />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/interventions/anevrisme" component={Anevrisme} />
          <Route exact path="/interventions/carotide" component={Carotide} />
          <Route exact path="/interventions/arterite" component={Arterite} />
          <Route exact path="/interventions/fistule" component={Fistule} />
          <Route exact path="/interventions/varices" component={Varices} />
          <Route exact path="/mentionslegales" component={MentionsLegales} />
        </div>
      </div>
    </MyProvider>
  );
}

export default App;
